import React, { useEffect } from "react";
import data from "./data";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";

export const Hero = () => {
  const hero = data[0].hero;

  return (
    <div className="flex-row justify-center items-center ">
      <div className="bg-hero-pattern align-text-bottom bg-center bg-cover bg-no-repeat w-full top-0">
        {/*<img src={saibaba} alt="" className="w-100 h-96 object-cover" />*/}

        <div
          id="carouselAutoplaying"
          className="carousel slide"
          data-bs-ride="carousel"
        >
          <div className="carousel-inner">
            {hero.map((item, id) => {
              return (
                <div
                  className={
                    id === 0 ? "carousel-item active" : "carousel-item"
                  }
                  key={id}
                >
                  <img
                    src={item.img}
                    className="d-block w-100 h-[400px] object-cover"
                    alt="..."
                  />
                </div>
              );
            })}
            {/*<div className="carousel-item active">
              <img src={saibaba} className="d-block w-100 h-86 object-cover" alt="..." />
          </div>*/}
          </div>
          <button
            className="carousel-control-prev"
            type="button"
            data-bs-target="#carouselAutoplaying"
            data-bs-slide="prev"
          >
            <span
              className="carousel-control-prev-icon"
              aria-hidden="true"
            ></span>
            <span className="visually-hidden">Previous</span>
          </button>
          <button
            className="carousel-control-next"
            type="button"
            data-bs-target="#carouselAutoplaying"
            data-bs-slide="next"
          >
            <span
              className="carousel-control-next-icon"
              aria-hidden="true"
            ></span>
            <span className="visually-hidden">Next</span>
          </button>
        </div>
      </div>
    </div>
  );
};

export const RoomSlider = () => {
  const rooms = data[0].rooms;

  return (
    <div className="flex-row justify-center items-center ">
      <div className=" my-3">
        <Carousel
          autoPlay
          infiniteLoop
          stopOnHover
          swipeable
          showThumbs={false}
        >
          {rooms.map((room, id) => {
            return (
              <div
                key={id}
                className=" flex justify-center items-center mx-auto w-4/5 h-64"
              >
                <img src={room.img} alt={room.title} />
              </div>
            );
          })}
        </Carousel>
      </div>
    </div>
  );
};
