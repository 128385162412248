import React, { useState } from "react";
import { FaAngleDoubleDown } from "react-icons/fa";
import { Link, Redirect } from "react-router-dom";
import "./Tab.css";
export default function RoomSelection() {
  const [name, setName] = useState([]);
  const [arrowDown, setArrowDown] = useState(false);
  const [gender, setGender] = useState([]);
  const [reservedSeat, setReservedSeat] = useState([
    "101",
    "202",
    "302",
    "401",
    "601",
  ]);
  const [seatNumber, setSeatnumber] = useState([]);
  // const [passengers, setPassengers] = useState([])
  // useEffect(()=>{
  //     let bId = localStorage.getItem('selectedBusId')
  //     if(bId){
  //         getSeatArray(bId)
  //     }
  //     else return
  // },[localStorage])
  // const getSeatArray = async bId => {
  //     const baseURL = "http://localhost:8080/booking/"
  //     await axios.get(baseURL, bId)
  //     .this(response=>response.data)
  //     .this(data=>{
  //         setReservedSeat(data)
  //         console.log(reservedSeat)
  //     })
  // }
  const getSeatNumber = (e) => {
    renderPassengerData(seatNumber);
    let newSeat = e.target.value;
    if (reservedSeat.includes(newSeat)) {
      e.disabled = true;
      if (seatNumber.includes(newSeat)) {
        setSeatnumber(seatNumber.filter((seat) => seat !== newSeat));
      }
    } else {
      setSeatnumber([...seatNumber, newSeat]);
      setReservedSeat([...reservedSeat, newSeat]);
      console.log(seatNumber);
    }
  };
  const handleGender = (e, seatNo) => {
    const { value } = e.target;
    setGender(gender.concat(value));
    // console.log(value)
    // setPassengers(prevState => ({ ...prevState, SeatNo: seatNo, Gender: value }))
  };
  const handlePassengerName = (e, seatNo) => {
    e.preventDefault();
    let value = e.target.value;
    // console.log(value)
    if (!value) {
      return setName("name is required");
    } else {
      setName(name.concat(value));
      // setPassengers(prevState => ({ ...prevState, SeatNo: seatNo, Name: value }))
    }
  };
  const handleSubmitDetails = (e) => {
    e.preventDefault();
    // setArrowDown(true);
    localStorage.setItem("reservedSeats", JSON.stringify(seatNumber));
    localStorage.setItem("nameData", JSON.stringify(name));
    window.alert("Seat Confirmed.");
    window.location.href = "/pay";
  };

  const renderPassengerData = (seatArray) => {
    return seatArray.map((seat, idx) => {
      return (
        <div>
          <form key={idx} className="form seatfrm">
            <p className="text-capitalize text-center">Seat No:{seat}</p>
            <input
              className="form-control seatInp"
              onBlur={(e) => handlePassengerName(e, seat)}
              type="text"
              name="passenger-name"
              placeholder="Enter Name"
            />
            <div className="form-check form-check-inline">
              <input
                className="form-check-input"
                type="radio"
                name="gender"
                id="male"
                value="Male"
                onClick={(e) => handleGender(e, seat)}
              />
              <label className="form-check-label" for="male">
                Male
              </label>
            </div>
            <div className="form-check form-check-inline">
              <input
                className="form-check-input"
                type="radio"
                name="gender"
                id="female"
                value="Female"
                onClick={(e) => handleGender(e, seat)}
              />
              <label className="form-check-label" htmlFor="female">
                Female
              </label>
            </div>
          </form>
        </div>
      );
    });
  };
  return (
    <div>
      {" "}
      <div>
        <ul className="nav nav-pills">
          <li className="nav-item">
            <a className="nav-link active" data-toggle="pill" href="/rs">
              Select Seat
            </a>
          </li>
          <li className="nav-item">
            <a className="nav-link" data-toggle="pill" href="/pay">
              Payment
            </a>
          </li>
        </ul>
      </div>
      <div className="ss">
        <div className="row">
          <div className="column1">
            <div className="plane">
              <form onChange={(e) => getSeatNumber(e)}>
                <ol className="cabin fuselage">
                  <li className="row row--1">
                    <ol className="seats" type="A">
                      <li className="seat">
                        <input type="checkbox" disabled value="101" id="101" />
                        <label htmlFor="101">101</label>
                      </li>
                      <li className="seat">
                        <input type="checkbox" id="102" value="102" />
                        <label htmlFor="102">102</label>
                      </li>
                    </ol>
                  </li>
                  <li className="row row--2">
                    <ol className="seats" type="A">
                      <li className="seat">
                        <input type="checkbox" disabled value="201" id="201" />
                        <label htmlFor="201">201</label>
                      </li>
                      <li className="seat">
                        <input type="checkbox" disabled value="202" id="202" />
                        <label htmlFor="202">202</label>
                      </li>
                    </ol>
                  </li>
                  <li className="row row--3">
                    <ol className="seats" type="A">
                      <li className="seat">
                        <input type="checkbox" value="301" id="301" />
                        <label htmlFor="301">301</label>
                      </li>
                      <li className="seat">
                        <input type="checkbox" disabled value="302" id="3B" />
                        <label htmlFor="302">302</label>
                      </li>
                    </ol>
                  </li>
                  <li className="row row--4">
                    <ol className="seats" type="A">
                      <li className="seat">
                        <input type="checkbox" disabled value="401" id="401" />
                        <label htmlFor="401">401</label>
                      </li>
                      <li className="seat">
                        <input type="checkbox" value="402" id="402" />
                        <label htmlFor="402">402</label>
                      </li>
                    </ol>
                  </li>
                  <li className="row row--5">
                    <ol className="seats" type="A">
                      <li className="seat">
                        <input type="checkbox" value="501" id="501" />
                        <label htmlFor="501">501</label>
                      </li>
                      <li className="seat">
                        <input type="checkbox" value="502" id="502" />
                        <label htmlFor="502">502</label>
                      </li>
                    </ol>
                  </li>
                  <li className="row row--6">
                    <ol className="seats" type="A">
                      <li className="seat">
                        <input type="checkbox" disabled value="601" id="601" />
                        <label htmlFor="601">601</label>
                      </li>
                      <li className="seat">
                        <input type="checkbox" value="602" id="602" />
                        <label htmlFor="602">602</label>
                      </li>
                    </ol>
                  </li>
                  <li className="row row--7">
                    <ol className="seats" type="A">
                      <li className="seat">
                        <input type="checkbox" value="701" id="701" />
                        <label htmlFor="701">701</label>
                      </li>
                      <li className="seat">
                        <input type="checkbox" disabled value="702" id="702" />
                        <label htmlFor="702">702</label>
                      </li>
                    </ol>
                  </li>
                  <li className="row row--8">
                    <ol className="seats" type="A">
                      <li className="seat">
                        <input type="checkbox" value="801" id="801" />
                        <label htmlFor="801">801</label>
                      </li>
                      <li className="seat">
                        <input type="checkbox" disabled value="802" id="802" />
                        <label htmlFor="802">802</label>
                      </li>
                    </ol>
                  </li>
                  <li className="row row--9">
                    <ol className="seats" type="A">
                      <li className="seat">
                        <input type="checkbox" value="901" id="901" />
                        <label htmlFor="901">901</label>
                      </li>
                      <li className="seat">
                        <input type="checkbox" disabled value="902" id="902" />
                        <label htmlFor="902">902</label>
                      </li>
                    </ol>
                  </li>
                  <li className="row row--10">
                    <ol className="seats" type="A">
                      <li className="seat">
                        <input type="checkbox" value="1000" id="1000" />
                        <label htmlFor="1000">1000</label>
                      </li>
                      <li className="seat">
                        <input type="checkbox" value="1002" id="1002" />
                        <label htmlFor="1002">1002</label>
                      </li>
                    </ol>
                  </li>
                </ol>
              </form>
            </div>
          </div>
          <div className="column2">
            <div className="seatInfo">
              <form className="form-group">
                {renderPassengerData(seatNumber)}
              </form>
              <div>
                <button
                  onClick={(e) => handleSubmitDetails(e)}
                  className="btn btn-info seatBT"
                >
                  Confirm Details
                </button>
              </div>
              {/* <div className={arrowDown ? "activeArrow2" : "nonActive"}>
                <FaAngleDoubleDown />
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
