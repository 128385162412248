import React, { useState, useEffect } from "react";
import axios from "axios";

const Mmc = () => {
  const [mmc, setMmc] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const result = await axios("https://nimittech.com/sai_api/sai.php");

      setMmc(result.data);
    };

    fetchData();
  }, []);

  return (
    <div className="container mx-auto">
      <h3 className="text-center pt-5 text-2xl font-bold ">
        List of Managing Committee Members 2023
      </h3>
      <div className="flex flex-row justify-center items-center py-4 w-full text-center">
        <div className="row row-cols row-cols-lg-5 row-cols-md-3 row-cols-sm-1 g-5 py-4 w-full text-center">
          {mmc.map((m) => {
            return (
              <div className="col text-center py-3" key={m.id}>
                <div
                  className="card p-2 lg:mr-4 max-md:mb-4 w-full text-center"
                  key={m.id}
                >
                  <img
                    src={m.image || ""}
                    className="card-img-top h-[300px] w-100 rounded-3xl p-3"
                    alt={m.name}
                  />
                  <div className="card-title text-xl text-gray-200 bg-gradient-to-r from-nav-gradient-from to-nav-gradient-to py-4 px-3 text-start">
                    <h5>{m.name}</h5>
                    <p className="text-sm text-end">{m.profile}</p>
                  </div>

                  <div className="card-body">
                    <div className="row text-left">
                      <p>{m.info}</p>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Mmc;
