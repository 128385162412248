import React, { useState, useRef } from "react";
import axios from "axios";
import emailjs from "@emailjs/browser";

const Contact = () => {
  const form = useRef();

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [number, setNumber] = useState("");
  const [message, setMessage] = useState("");
  const [pending, setPending] = useState(false);

  const handleNameChange = (e) => {
    setName(e.target.value);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleMessageChange = (e) => {
    setMessage(e.target.value);
  };

  const handleNumberChange = (e) => {
    setNumber(e.target.value);
  };

  // Reset the form fields after submission

  const reset = () => {
    setName("");
    setEmail("");
    setNumber("");
    setMessage("");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_9jfkoiq",
        "template_v2rhb2r",
        form.current,
        "pnMMpWkob3bfgMszM"
      )
      .then(
        (result) => {
          setPending(false);
          alert(
            "I 've received your message. I'll get back to you as soon as possible."
          );
          form.current.reset();
        },
        (error) => {
          setPending(false);
          console.log(error.text);
          alert("Something went wrong. Please try again later.");
        }
      );

    // Reset the form fields after submission
    reset();
  };

  return (
    <div>
      <div className="container relative z-10">
        <div class="flex flex-wrap justify-center -mx-4 pt-5"><div class="w-full px-4"><div class="text-center mx-auto  lg:mb-  0 max-w-[510px]"><h1 class="text-3xl text-heading font-bold pb-7"><span class="text-secondary ld-fh-txt all-text-gradient">GET IN TOUCH WITH US</span></h1></div></div></div>
        <div className=" text-white rounded-3xl p-3 w-full overflow-hidden my-3">
          <div className="flex flex-col lg:justify-between -mx-4">
            <div className="w-full px-4">
              <div className="mb-12 lg:mb-0">
                <div className="row">
                  <div className="col-md-4">
                    <div className="color-box ">
                      <h5 className="mb-2 text-xl font-medium leading-tight text-white">
                        Call to Us
                      </h5>
                      <a
                        href="tel:01142011208"
                        className="mb-4 text-base w-full text-white hover:text-heading"
                      >
                        011 42011208
                      </a>
                      , <br />
                      <a
                        href="tel:01142011209"
                        className="mb-4 text-base w-full text-white hover:text-heading"
                      >
                        011 42011209
                      </a>
                      , <br />
                      <a
                        href="tel:01143515848"
                        className="mb-4 text-base w-full text-white hover:text-heading"
                      >
                        011 43515848
                      </a>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="color-box ">
                      <h5 className="mb-2 text-xl font-medium leading-tight text-white ">
                        Write Mail to Us
                      </h5>
                      <a
                        href="mailto:contact@saimemorial.com"
                        className="mb-4 text-base text-white hover:text-heading"
                      >
                        contact@saimemorial.com
                      </a>{" "}
                      <br />
                      <a
                        href="mailto:saimemorialdelhi@gmail.com"
                        className="mb-4 text-base text-white hover:text-heading"
                      >
                        saimemorialdelhi@gmail.com
                      </a>
                    </div>
                  </div>
                  <div className="col-md-4">

                    <div className="color-box">
                      <h5 className="mb-2 text-xl font-medium leading-tight text-white ">
                        Reach to Us
                      </h5>
                      <address className="text-base text-white hover:text-heading break-words">
                        Shri Sai Bhakta Samaj (Regd.) 17, <br />
                        Institutional Area, Lodhi Road, <br /> New Delhi - 110003
                      </address>
                    </div>
                  </div>


                </div>
              </div>
            </div>
            <div className="w-full px-4">
              <div className="bg-white relative rounded-3xl p-8 sm:p-12 shadow-lg">
                <form onSubmit={handleSubmit} ref={form}>
                  <div className="mb-6">
                    <input
                      type="text"
                      name="name"
                      value={name}
                      onChange={handleNameChange}
                      placeholder="Your Name"
                      className=" w-full rounded py-3 px-[14px] text-body-color text-base border border-[f0f0f0] outline-none focus-visible:shadow-none focus:border-gray-700
                      "
                      required
                    />
                  </div>
                  <div className="mb-6">
                    <input
                      type="email"
                      name="email"
                      value={email}
                      onChange={handleEmailChange}
                      placeholder="Your Email"
                      className="
                        w-full
                        rounded
                        py-3
                        px-[14px]
                        text-body-color text-base
                        border border-[f0f0f0]
                        outline-none
                        focus-visible:shadow-none
                        focus:border-gray-700
                        "
                      required
                    />
                  </div>
                  <div className="mb-6">
                    <input
                      type="text"
                      name="number"
                      value={number}
                      onChange={handleNumberChange}
                      placeholder="Your Phone"
                      className="
                        w-full
                        rounded
                        py-3
                        px-[14px]
                        text-body-color text-base
                        border border-[f0f0f0]
                        outline-none
                        focus-visible:shadow-none
                        focus:border-gray-700
                        "
                      required
                    />
                  </div>
                  <div className="mb-6">
                    <textarea
                      rows="6"
                      name="message"
                      value={message}
                      onChange={handleMessageChange}
                      placeholder="Your Message"
                      className=" w-full rounded py-3 px-[14px] text-body-color text-base border border-[f0f0f0] resize-none outline-none focus-visible:shadow-none focus:border-gray-700"
                      required
                    ></textarea>
                  </div>
                  <div className="text-center">
                    <button
                      type="submit"
                      disabled={pending ? true : false}
                      className="text-white bg-gradient-to-r from-btn-from to-btn-to hover:bg-nav-gradient-from focus:bg-nav-gradient-from rounded border border-gray-700 p-3 transition hover:bg-opacity-90"
                    >
                      Send Message
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
