import React from "react";
import { Link } from "react-router-dom";
import {
  RiFacebookCircleLine,
  RiInstagramLine,
  RiTwitterLine,
  RiYoutubeLine,
} from "react-icons/ri";

const Footer = () => {
  return (
    <div className="bg-gradient-to-r from-foo-left to-foo-right bottom-0 p-4 w-100">
      {/* <div className="flex flex-col md:flex-row w-100 justify-center bg-gradient-to-r from-foo-left to-foo-right mr-0">
        <div className="flex flex-col items-center justify-center w-full h-full max-md:pb-5 text-white ">
          <h1 className="text-2xl pb-2">Quick Link</h1>
          <div className="flex flex-row justify-center w-full h-full text-white ">
            <ul className="px-3">
              <li className=" text-start mx-3 leading-6 py-2">
                <Link
                  className=" text-start leading-6 px-3  hover:text-gray-100 hover:font-bold"
                  to="/"
                >
                  Home
                </Link>
              </li>
              <li className=" text-start mx-3 leading-6 py-2">
                <Link
                  className=" text-start leading-6 px-3  hover:text-gray-100 hover:font-bold"
                  to="/temple"
                >
                  Sai Temple
                </Link>
              </li>
              <li className=" text-start mx-3 leading-6 py-2">
                <Link
                  className=" text-start leading-6 px-3  hover:text-gray-100 hover:font-bold"
                  to="/news"
                >
                  Notification
                </Link>
              </li>
              <li className=" text-start mx-3 leading-6 py-2">
                <Link
                  className=" text-start leading-6 px-3  hover:text-gray-100 hover:font-bold"
                  to="/accomodations"
                >
                  Online Booking
                </Link>
              </li>
              <li className=" text-start mx-3 leading-6 py-2">
                <Link
                  className=" text-start leading-6 px-3  hover:text-gray-100 hover:font-bold"
                  to="/contact"
                >
                  Contact Us
                </Link>
              </li>
            </ul>
            <ul className="border-l">
              <li className=" text-start mx-3 leading-6 py-2">
                <Link
                  className=" text-start leading-6 px-3  hover:text-gray-100 hover:font-bold"
                  to="/aboutus"
                >
                  About Us
                </Link>
              </li>
              <li className=" text-start mx-3 leading-6 py-2">
                <Link
                  className=" text-start leading-6 px-3  hover:text-gray-100 hover:font-bold"
                  to="/mmc"
                >
                  Member of Managing Committee
                </Link>
              </li>
            </ul>
          </div>
        </div>
        <div className="flex flex-col items-center justify-center max-md:justify-start w-full h-full max-md:pb-5 text-white ">
          <h1 className="text-2xl pb-2">Follow Us On</h1>
          <div className="flex flex-row justify-center  w-full h-full text-white ">
            <ul>
              <li className="flex items-center justify-between text-start -5 leading-6 py-2">
                <a
                  href="https://facebook.com"
                  className="flex items-center justify-between text-start leading-6 hover:text-heading hover:text-gray-100 hover:font-bold"
                >
                  Facebook
                  <RiFacebookCircleLine className="ml-3 text-xl" />
                </a>
              </li>
              <li className="flex items-center justify-between text-start -5 leading-6 py-2">
                <a
                  href="https://instagram.com"
                  className="flex items-center justify-between text-start leading-6 hover:text-heading hover:text-gray-100 hover:font-bold"
                >
                  Instagram
                  <RiInstagramLine className="ml-3 text-xl" />
                </a>
              </li>
              <li className="flex items-center justify-between text-start -5 leading-6 py-2">
                <a
                  href="https://twitter.com"
                  className="flex items-center justify-between text-start leading-6 hover:text-heading hover:text-gray-100 hover:font-bold"
                >
                  Twitter
                  <RiTwitterLine className="ml-3 text-xl" />
                </a>
              </li>
              <li className="flex items-center justify-between text-start -5 leading-6 py-2">
                <a
                  href="https://youtube.com"
                  className="flex items-center justify-between text-start leading-6 hover:text-heading hover:text-gray-100 hover:font-bold"
                >
                  YouTube
                  <RiYoutubeLine className="ml-3 text-xl" />
                    
             
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className="flex flex-col items-center justify-center w-full h-full max-md:pb-5 text-white ">
          <h1 className="text-2xl pb-2">Reach Us On</h1>
          <div className="flex flex-row justify-center w-full h-full text-white ">
            <address className="text-start">
              Address : <br />
              17, Institutional Area, Lodhi Road, New Delhi - 110003
              <br /> <br />
              Helpline No.: <br />
              <a href="tel:01142011208" className="hover:text-heading">
                011 42011208
              </a>
              ,{" "}
              <a href="tel:01142011209" className="hover:text-heading">
                42011209
              </a>
              ,{" "}
              <a href="tel:01143515848" className="hover:text-heading">
                43515848
              </a>
              <br /> <br />
              Mail us at : <br />
              <a
                href="mailto:saimemorialdelhi@gmail.com"
                className="hover:text-heading"
              >
                saimemorialdelhi@gmail.com
              </a>
              {","} <br />
              <a
                href="mailto:contact@saimemorial.com"
                className="hover:text-heading"
              >
                contact@saimemorial.com
              </a>
            </address>
          </div>
        </div>

      </div> */}
      <div class="container">
        <div className="row pt-3 text-white">
          <div className="col-md-4">
            <div className="">
              <div className="px-4">
                <h1 class="text-2xl pb-2">Useful Link</h1>
              </div>
              <ul class="px-3">
                <li class=" text-start mx-3 leading-6 py-2"><a class=" text-start leading-6  hover:text-gray-100 hover:font-bold" href="/">Home</a></li>
                <li class=" text-start mx-3 leading-6 py-2"><a class=" text-start leading-6  hover:text-gray-100 hover:font-bold" href="/temple">Sai Temple</a></li>
                <li class=" text-start mx-3 leading-6 py-2"><a class=" text-start leading-6  hover:text-gray-100 hover:font-bold" href="/news">Notification</a></li>
                <li class=" text-start mx-3 leading-6 py-2"><a class=" text-start leading-6  hover:text-gray-100 hover:font-bold" href="/accomodations">Online Booking</a></li>
                <li class=" text-start mx-3 leading-6 py-2"><a class=" text-start leading-6  hover:text-gray-100 hover:font-bold" href="/contact">Contact Us</a></li>
                <li class=" text-start mx-3 leading-6 py-2"><a class=" text-start leading-6  hover:text-gray-100 hover:font-bold" href="/aboutus">About Us</a></li>
                <li class=" text-start mx-3 leading-6 py-2"><a class=" text-start leading-6  hover:text-gray-100 hover:font-bold" href="/mmc">Member of Managing Committee</a></li>
              </ul>
            </div>
          </div>
          <div className="col-md-4">
            <div className="px-4">
              <h1 class="text-2xl pb-2">Social Initiatives</h1>
            </div>
            <div className="">
              <ul class="px-3">
                <li class=" text-start mx-3 leading-6 py-2"><a class=" text-start leading-6  hover:text-gray-100 hover:font-bold" href="#">Aarti</a></li>
                <li class=" text-start mx-3 leading-6 py-2"><a class=" text-start leading-6  hover:text-gray-100 hover:font-bold" href="#">Darshan</a></li>
                <li class=" text-start mx-3 leading-6 py-2"><a class=" text-start leading-6  hover:text-gray-100 hover:font-bold" href="#">Pooja</a></li>
                <li class=" text-start mx-3 leading-6 py-2"><a class=" text-start leading-6  hover:text-gray-100 hover:font-bold" href="#">Accomodation</a></li>
                <li class=" text-start mx-3 leading-6 py-2"><a class=" text-start leading-6  hover:text-gray-100 hover:font-bold" href="#">Publication</a></li>
              </ul>
            </div>
          </div>
          <div className="col-md-4">
            <div className="px-4">
              <h1 class="text-2xl pb-2">Reach Us On</h1>
            </div>
            <div className="">
              <ul class="px-3">
                <li class=" text-start mx-3 leading-6 py-2">Address :<br></br>
                  17, Institutional Area, Lodhi Road, New Delhi - 110003</li>
                <li class=" text-start mx-3 leading-6 py-2">Helpline No.:<br></br>
                  <a href="tel:01142011208" class="text-start leading-6  hover:text-gray-100 hover:font-bold">011 42011208</a>, <a href="tel:01142011209" class="text-start leading-6  hover:text-gray-100 hover:font-bold">42011209</a>, <a href="tel:01143515848" class="text-start leading-6  hover:text-gray-100 hover:font-bold">43515848</a></li>
                <li class=" text-start mx-3 leading-6 py-2">Mail us at :<br></br>
                  <a href="mailto:saimemorialdelhi@gmail.com" class="text-start leading-6  hover:text-gray-100 hover:font-bold">saimemorialdelhi@gmail.com</a> ,
                  <a href="mailto:contact@saimemorial.com" class="text-start leading-6  hover:text-gray-100 hover:font-bold">contact@saimemorial.com</a></li>
                <li class=" text-start mx-3 leading-6 py-2">
                  <div className="pb-2 pt-3">
                    <h1 class="text-2xl pb-2">Follow Us On</h1>
                  </div>
                  <ul class="social-icon social-icon-md flex">
                    <li><a target="_blank" aria-label="DJJS Facebook" href="#"> <RiFacebookCircleLine className=" text-xl" /></a></li>
                    <li><a target="_blank" aria-label="DJJS Twitter" href="#"><RiTwitterLine className="ml-3 text-xl" /></a></li>
                    <li><a target="_blank" aria-label="DJJS Youtube" href="#">  <RiYoutubeLine className="ml-3 text-xl" /></a></li>
                    <li><a target="_blank" aria-label="DJJS Instagram" href="#"> <RiInstagramLine className="ml-3 text-xl" /></a></li>

                  </ul>
                </li>
              </ul>
            </div>
          </div>


        </div>
        <div className="row pt-5">
          <div className="col-md-12">
            <div className="flex flex-col items-center justify-center w-full h-full max-md:pb-5 text-white ">
              <p>© Copyright 2023 Shri Sai Bhakta Samaj, Lodhi Road, New Delhi</p>
            </div>
          </div>
        </div>
      </div>

      <div>

      </div>
    </div>

  );
};

export default Footer;
