import React from "react";
import { Link } from "react-router-dom";
import { logo } from "../img";

const Topheader = () => {
  return (
    <div>
      {/* <div className="flex items-center justify-content-center bg-white py-3">
        <Link
          to="/"
          className="text-gray-800 header-xl:text-xl header-sm:text-bases font-bold hover:text-heading"
        >
          <h1>Shri Sai Bhakta Samaj, Lodhi Road, New Delhi</h1>
        </Link>
      </div> */}
      <div className="bg-white">
        <div className="container">
          <div className="row ">
            <div className="col-md-1">
              <div className="pt-1 pb-1">
                <a target="_blank" href="#">
                  <img
                    src={logo}
                    alt=""
                    className="block text-center logo-image"
                  />
                </a>
              </div>
            </div>

            <div className="col-md-8">
              <div className="pt-4 pb-3">

                <Link
                  to="/"
                  className="text-gray-800 header-xl:text-xl header-sm:text-bases font-bold hover:text-heading"
                >

                  <h1 class="text-3xl text-heading font-bold"><span class="text-secondary ld-fh-txt all-text-gradient" >Shri Sai Bhakta Samaj, Lodhi Road, New Delhi</span></h1>
                  {/* <h1>Shri Sai Bhakta Samaj, Lodhi Road, New Delhi</h1> */}
                </Link>
              </div>
            </div>

            <div className="col-md-3 float-right">
              <div className="pt-3 pb-3">
                <button className="bg-gradient-to-r from-btn-from to-btn-to text-white font-bold py-2 px-4 rounded">
                  <Link to="/donation">Donation</Link>
                </button>
              </div>
            </div>

          </div>
        </div>
      </div>



    </div>
  );
};

export default Topheader;
