import React from "react";
import person1 from "../img/person1.png";
import person2 from "../img/person2.png";
import person3 from "../img/person3.png";
import { Link } from "react-router-dom";

const Aboutus = () => {
  return (
    <div className="container mx-auto px-4 lg:px-10">
      <div className="bg-gray-400 text-gray-800 rounded-3xl p-3 w-full overflow-hidden my-3">
        <div className="flex flex-col justify-center items-center w-full text-lg  p-2">
          <h1 className="pt-3 text-heading text-2xl font-bold">About us</h1>
          <div className="flex justify-center items-center">
            <div className="p-2">
              <p>
                <strong className="text-heading">
                  Shri Sai Bhakta Samaj :
                </strong>{" "}
                (Regd.) was established in October 1968 with its Regd. Office at
                27, Rajpur Road, Delhi being the residence of the then President
                Mr. R.S. Chitnis alongwith the following office bearers and
                members of Managing Committee with the noble objects to spread
                Baba's name/teaching amongst the public in General and devotees
                of Shri Sai Baba in particular, the spirit of universal
                brotherhood or religious toleration as embodied in Sai Bhakti,
                high principles of conduct and character without distinction of
                caste, creed and religion through mandir amongst other objects
                such as publishing magazines, periodicals, books etc. To promote
                and carry out services of all kinds which Holy SAI BABA had
                rendered to humanity. To establish libraries, regional centers,
                hospitals, Dharamshalas to help the poor and distressed to
                relieve human sufferings and improve standard of living and
                conduct. To promote unity among people belonging to different
                religions, caste and creed and work towards national integration
                with the following as office bearers and members of the 1st
                Managing Committee.
              </p>
            </div>
          </div>

          <div className="flex flex-col justify-start items-center w-full bg-transparent py-5">
            <h1 className="text-xl font-bold text-heading">
              First Managing Committee:
            </h1>
            <ul className=" lg:w-1/2 md:w-72">
              <li className="flex justify-between text-gray-800 leading-6 border-b-gray-500">
                <p className="text-base min-md:text-lg lg:text-xl font-bold leading-6">
                  Shri R.S. Chitnis
                </p>
                <p className="text-base min-md:text-lg lg:text-xl leading-6 pl-5 text-start">
                  President
                </p>
              </li>
              <li className="flex justify-between text-gray-800 leading-6 border-b-gray-500">
                <p className="text-base min-md:text-lg lg:text-xl font-bold leading-6">
                  Shri N.H. Kalgavakar
                </p>
                <p className="text-base min-md:text-lg lg:text-xl leading-6 pl-5 text-start">
                  Vice President
                </p>
              </li>
              <li className="flex justify-between text-gray-800 leading-6 border-b-gray-500">
                <p className="text-base min-md:text-lg lg:text-xl font-bold leading-6">
                  Shri K.B. Grover
                </p>
                <p className="text-base min-md:text-lg lg:text-xl leading-6 pl-5 text-start">
                  Secretary
                </p>
              </li>
              <li className="flex justify-between text-gray-800 leading-6 border-b-gray-500">
                <p className="text-base min-md:text-lg lg:text-xl font-bold leading-6">
                  Shri Siri Krishan Kapur
                </p>
                <p className="text-base min-md:text-lg lg:text-xl leading-6 pl-5 text-start">
                  Jt. Secretary
                </p>
              </li>
              <li className="flex justify-between text-gray-800 leading-6 border-b-gray-500">
                <p className="text-base min-md:text-lg lg:text-xl font-bold leading-6">
                  Shri Warman Varulkar
                </p>
                <p className="text-base min-md:text-lg lg:text-xl leading-6 pl-5 text-start">
                  Treasurer
                </p>
              </li>
              <li className="flex justify-between text-gray-800 leading-6 border-b-gray-500">
                <p className="text-base min-md:text-lg lg:text-xl font-bold leading-6">
                  Shri Krishan Mohan
                </p>
                <p className="text-base min-md:text-lg lg:text-xl leading-6 pl-5 text-start">
                  Jt. Treasurer
                </p>
              </li>
            </ul>
          </div>
          <div className="flex flex-col justify-start items-center w-full bg-transparent py-5">
            <h1 className="text-xl font-bold text-heading">
              Current Managing Committee:
            </h1>
            <ul className=" lg:w-1/2 md:w-72">
              <li className="flex justify-between text-gray-800 leading-6 border-b-gray-500">
                <p className="text-base min-md:text-lg lg:text-xl font-bold leading-6">
                  Mr. Sushil Kapoor
                </p>
                <p className="text-base min-md:text-lg lg:text-xl leading-6 pl-5 text-start">
                  President
                </p>
              </li>
              <li className="flex justify-between text-gray-800 leading-6 border-b-gray-500">
                <p className="text-base min-md:text-lg lg:text-xl font-bold leading-6">
                  Mr. R.K. Oberoi
                </p>
                <p className="text-base min-md:text-lg lg:text-xl leading-6 pl-5 text-start">
                  Vice President
                </p>
              </li>
              <li className="flex justify-between text-gray-800 leading-6 border-b-gray-500">
                <p className="text-base min-md:text-lg lg:text-xl font-bold leading-6">
                  Mr. T.D. Monga
                </p>
                <p className="text-base min-md:text-lg lg:text-xl leading-6 pl-5 text-start">
                  Jr. Vice President
                </p>
              </li>
              <li className="flex justify-between text-gray-800 leading-6 border-b-gray-500">
                <p className="text-base min-md:text-lg lg:text-xl font-bold leading-6">
                  Mrs. Shalini Uppal
                </p>
                <p className="text-base min-md:text-lg lg:text-xl leading-6 pl-5 text-start">
                  Joint. Secretary
                </p>
              </li>
              <li className="flex justify-between text-gray-800 leading-6 border-b-gray-500">
                <p className="text-base min-md:text-lg lg:text-xl font-bold leading-6">
                  Mr. K.M. Bhatnagar
                </p>
                <p className="text-base min-md:text-lg lg:text-xl leading-6 pl-5 text-start">
                  Treasurer
                </p>
              </li>
              <li className="flex justify-between text-gray-800 leading-6 border-b-gray-500">
                <p className="text-base min-md:text-lg lg:text-xl font-bold leading-6">
                  Mr. S.K. Gupta
                </p>
                <p className="text-base min-md:text-lg lg:text-xl leading-6 pl-5 text-start">
                  Jt. Treasurer
                </p>
              </li>
            </ul>
            <button className="bg-btn-sec px-5 py-3 ml-5 mt-3 rounded text-nav-text text-base font-semibold hover:bg-nav-text hover:text-gray-800">
              <Link to="/mmc">More</Link>
            </button>
          </div>
          <div className="flex flex-col md:flex-row justify-center items-center">
            <p>
              Shri Sushil Kapoor, B.D. Kapoor, K.M. Bhatnagar, Mrs. Padamja R.
              Chitnis as members amongst some of the employees of Mr. R.S.
              Chitnis. <br /> Efforts started to arrange land for constructing a
              Sai Memorial and with the grace & blessings of Lord SAI & sincere
              and continuous efforts by the office bearers & members were able
              to get 17,
              <br /> Institutional Area, Lodhi Road, Plot from Land &
              Development office New Delhi for constructing, a Sai Memorial and
              this was the beginning of a dream of devotees coming true and this
              is the first Shirdi Sai Memorial in Northern India.
            </p>
          </div>
          <div className="flex flex-col md:flex-row justify-center items-center py-3">
            <div className="w-4/5 lg:w-2/3 sm:w-full px-4 sm:px-1 text-base">
              <p className="pb-3">
                Shri Krishan Chand Dhawan, Shri Bhag Chand Taneja both
                businessmen came forward and joined hands to expedite the
                constructing of the memorial.
              </p>
              <p className="pb-3">
                Shri R.S. Sharma, Shri S.C.Gupta, Shri M.M. Lal then also joined
                and all started to achieve the goal. Foundation stone laying
                ceremony was held in the month of August 1972. A souvenir was
                published on the auspicious occasion. It carried the messages of
                best wishes from a number of dignitaries including Hon'ble,
                President & Vice -President of India{" "}
              </p>
              <p>
                M/s Pardhan Ghosh & Associates were consultant Architects who
                supervised the progress of the construction with all devotion
                and the building as we see today is a symbol of their and
                contractor's sincere and hard work mainly under the supervision
                of Mr. Chitnis.
              </p>
            </div>
            <div className="max-md:w-2/3 p-2 ">
              <img src={person1} alt="" className="rounded-xl" />
              <p className="text-heading">Sh. Bhag Chand Taneja</p>
            </div>
          </div>
          <div className="flex flex-col md:flex-row justify-center items-center py-3">
            <div className="w-4/5 lg:w-2/3 px-4">
              <p>
                The Statue of Lord SAI was now the first priority and efforts
                started in that direction. After consulting various sculptures
                it was decided to go to Jaipur. Shri R.S. Chitnis accompanied by
                Shri SiriKrishan Kapur & Shri S. C. Gupta visited Jaipur
              </p>
            </div>
          </div>
          <div className="flex flex-col md:flex-row justify-center items-center py-3">
            <div className="max-md:w-1/3 p-2">
              <img src={person2} alt="" className="rounded-xl" />
              <p className="text-heading">Sh. S. K. Kapur</p>
            </div>
            <div className="w-4/5 lg:w-2/3 px-4">
              <p>
                M/s Rajasthan Murti Kala Kendra was selected to make the statue
                of Baba as per Shirdi Statue of white flawless marble of high
                quality. Shri Rameshwar Lal & Manohar Lal both brothers were the
                partners. Arrangements were made for their visit to Shirdi and a
                clay model was made during stay in Shirdi. The price of the Idol
                was agreed at as Rs.35,000/- and a sum of Rs, 5,000/- was paid
                as advance. We had to make a number of visits (say about 15) to
                Jaipur. The group comprised Shri R.S. Chitnis, Shri K.C. Dhawan,
                Shri R.S. Sharma, Shri S.C. Gupta, Shri Siri Krishan Kapur, Shri
                Sushil Kapur. A lot of efforts were put by all to see that the
                effect of Shirdi Statue could be brought. Shri R.S. Sharma and
                Shri S.C. Gupta were very particular in this regard.
              </p>
            </div>
          </div>
          <div className="flex flex-col md:flex-row justify-center items-center py-3">
            <div className="w-4/5 lg:w-2/3 px-4">
              <p>
                At the time of taking the delivery full team went to Jaipur and
                came back S/Shri R.S. Sharma, Sushil Kapoor & Shri Pran Nath
                Verma an Income Tax Officer and a staunch devotee of Baba stayed
                behind and they accompanied the truck transporting the statue in
                a separate vehicle. A team of South Indian Pandits arranged by
                Shri R.S. Sharma performed Havan & Puja for three days prior to
                the installation ceremony on 30.09.1976. This was a new
                experience as the performance of south Indian Pandits was
                befitting the occasion. Prior to the installation of the statue,
                painted picture of Baba sitting on stone, which is now installed
                in the Balcony, was kept for Puja in the hall. After
                establishment of the Samaj we, with a team of some employees of
                Mr. Chitnis who had formed a Qawalls group and used to sing
                Qawalies & Sai Bhajans; on behalf of Samaj used to arrange
                Bhajans at the houses of members and others devotees and the
                offering collected were credited to Samaj account for
                construction of the memorial building.
              </p>
            </div>
            <div className="max-md:w-1/2 p-2">
              <img src={person3} alt="" className="rounded-xl" />
              <p className="text-heading">Sh. K.C. Dhawan</p>
            </div>
          </div>
          <div className="flex flex-col md:flex-row justify-center items-center py-3">
            <div className="w-full lg:w-full px-4">
              <p>
                Mr. Chitnis used to collect donations from distributors of the
                films who wanted to screen their films at both cinema houses and
                the donations so collected were also credited to the Samaj
                account to be used for construction For a long time every
                Thursday Pooja, Aarti and Bhajans were conducted at the
                residence of Shri B.D. Kapoor where Mr. Chitnis also used to
                join regularly. This was the aim of all of us to collect funds
                for the purpose. Donations collected by all the members were for
                this purpose and Mr. Chitnis used to issue monthly report giving
                details of the funds collected by each member. It may also be
                mentioned that with a view to protect the devotees from sun &
                rain. Fiber glass sheds were erected. Unfortunately due to
                accidental fire on 5/6/2002, the sheds were burnt and the sheds
                as we see now are excellent one and non-combustible. Mercury
                lights have been fixed which give an excellent view After the
                fire incident devotees have come forward in numbers and offered
                donation in cash and kind liberally for renovation of the
                building.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Aboutus;
