import React, { useState, useEffect } from "react";

const RoomBookingComponent = () => {
  const [availability, setAvailability] = useState([]);
  const [selectedDate, setSelectedDate] = useState("");
  const [roomsToBook, setRoomsToBook] = useState(0);

  // Function to calculate the availability for the next 45 days
  const calculateAvailability = () => {
    const today = new Date();
    const days = 45;
    const availabilityData = [];

    for (let i = 0; i < days; i++) {
      const currentDate = new Date(today);
      currentDate.setDate(today.getDate() + i);

      // Replace this with your logic to fetch the booked room count for each day from your backend or data source
      // For demonstration purposes, we'll set random available and booked room counts for each day.
      const availableRooms = Math.floor(Math.random() * 100); // Random number of available rooms (0 to 99)
      const bookedRooms = Math.floor(Math.random() * 10); // Random number of booked rooms (0 to 9)

      const availabilityInfo = {
        date: currentDate.toISOString().split("T")[0], // Get the date in 'YYYY-MM-DD' format
        availableRooms,
        bookedRooms,
      };

      availabilityData.push(availabilityInfo);
    }

    setAvailability(availabilityData);
  };

  useEffect(() => {
    const storedAvailability = localStorage.getItem("availability");
    if (storedAvailability) {
      setAvailability(JSON.parse(storedAvailability));
    } else {
      calculateAvailability();
    }
  }, []);

  // Function to handle room selection for a specific day
  const handleRoomSelection = (date, bookedRooms) => {
    const selectedRooms = parseInt(
      prompt(`How many rooms do you want to book for ${date}?`),
      10
    );

    if (
      !isNaN(selectedRooms) &&
      selectedRooms >= 0 &&
      selectedRooms <= availability[0].availableRooms
    ) {
      const updatedAvailability = availability.map((data) => {
        if (data.date === date) {
          return {
            ...data,
            availableRooms: data.availableRooms - selectedRooms,
            bookedRooms: bookedRooms + selectedRooms,
          };
        }
        return data;
      });

      setAvailability(updatedAvailability);
      localStorage.setItem("availability", JSON.stringify(updatedAvailability));
    } else {
      alert("Invalid selection. Please enter a valid number of rooms.");
    }
  };

  return (
    <div>
      <h2>Booking Availability for the Next 45 Days</h2>
      <table>
        <thead>
          <tr>
            <th>Date</th>
            <th>Available Rooms</th>
            <th>Booked Rooms</th>
            <th>Book Rooms</th>
          </tr>
        </thead>
        <tbody>
          {availability.map((data) => (
            <tr key={data.date}>
              <td>{data.date}</td>
              <td>{data.availableRooms}</td>
              <td>{data.bookedRooms}</td>
              <td>
                <button
                  disabled={data.availableRooms === 0}
                  onClick={() =>
                    handleRoomSelection(data.date, data.bookedRooms)
                  }
                >
                  Book
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default RoomBookingComponent;
