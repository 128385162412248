import React from "react";
import data from "../components/data";
import { RoomSlider, Hero } from "../components/Hero";
import { Tabs } from "../components/tabs";
import { Link } from "react-router-dom";

const OnlineBooking = () => {
  const booking = data[0].booking;
  return (
    <div className="flex-row justify-center items-center h-full w-100">
      <div className="flex-row justify-center items-center h-full w-100 my-auto relative">
        <Hero />
      </div>
      <div className="flex flex-col items-center px-5 mt-8 max-md:mt-5">
        <div className="flex flex-col lg:w-4/5 justify-center items-center max-md:w-full text-center">
          <h3 className="px-8 columns-1 max-md:px-1 pb-4 text-xl md:text-sm font-bold text-center">
            Shri Sai Bhakta Samaj, Lodhi Road, New Delhi, accommodation live
            availability as of time today Click Here .
          </h3>
          <p className="px-8 columns-1 max-md:px-1 md:text-sm text-center">
            Shri Sai Bhakta Samaj, Lodhi Road, New Delhi offers various
            accommodation options such as Sai Ashram, and New Bhakta Niwas which
            provides a pleasant stay at affordable prices. Book your stay online
            from below links.
          </p>
        </div>

        <div className="flex-row justify-center items-center h-full w-100">
          <div className="flex flex-col md:flex-row justify-center items-center py-4 w-full">
            <div className="flex flex-col md:flex-row justify-center items-center py-4 w-full">
              {booking.map((book) => {
                return (
                  <div
                    className="card p-2 lg:mr-4 max-md:mb-4 w-1/2 max-md:w-3/4 max-sm:w-full h- text-center"
                    key={book.id}
                  >
                    <h5 className="card-title text-xl text-gray-200 bg-gradient-to-r from-nav-gradient-from to-nav-gradient-to py-4">
                      {book.title}
                    </h5>
                    <img
                      src={book.img}
                      className="card-img-top h-[500px] w-100"
                      alt={book.title}
                    />
                    <RoomSlider />
                    <div className="card-body">
                      <div className="row text-left">
                        <button className="bg-gradient-to-r from-btn-from to-btn-to rounded text-white hover:text-white w-44 h-12">
                          <a href={book.youtube} className="">
                            Watch Video
                          </a>
                        </button>
                        <p className="py-3">
                          Location :{" "}
                          <a
                            href={book.location}
                            className="font-semibold hover:text-heading hover:underline"
                          >
                            Click here to see on map
                          </a>{" "}
                        </p>
                      </div>
                      <div className="row">
                        <table className="table align-middle w-4/5">
                          <tbody>
                            <tr className="flex justify-between items-center odd:bg-white even:bg-slate-50">
                              <td>Distance from temple </td>
                              <td>{book.dft}</td>
                            </tr>
                            <tr className="flex justify-between items-center odd:bg-white even:bg-slate-200">
                              <td>Distance from Bus Station</td>
                              <td>{book.dfbs}</td>
                            </tr>
                            <tr className="flex justify-between items-center odd:bg-white even:bg-slate-50">
                              <td>Distance Railway Station</td>
                              <td>{book.dfrs}</td>
                            </tr>
                          </tbody>
                        </table>
                        <p className="card-text py-3">{book.text}</p>
                        <div className="block justify-center items-center text-center mt-3">
                          <Link
                            to="/login"
                            className="btn bg-gradient-to-r from-btn-from to-btn-to rounded text-white hover:text-white w-44 h-12 text-center"
                          >
                            Book
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>

        {/* <div className="container flex-row lg:w-4/5 text-center mb-5">
          <Tabs />
        </div> */}
      </div>
    </div>
  );
};

export default OnlineBooking;
