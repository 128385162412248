import React, { useState, useEffect } from "react";
import axios from "axios";

const Saitemple = () => {
  const url = "https://nimittech.com/sai_api/temple.php";

  const [temple, setTemple] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const result = await axios(url);
      setTemple(result.data);
    };
    fetchData();
  }, []);
  return (
    <div>
      <div className="container mx-auto px-5 py-5 lg:px-32 lg:pt-24">
        <div class="flex flex-wrap justify-center  pt-2"><div class="w-full px-4"><div class="text-center mx-auto  lg:mb-0 max-w-[510px]"><h1 class="text-3xl text-heading font-bold pb-7"><span class="text-secondary ld-fh-txt all-text-gradient">Activities</span></h1></div></div></div>
        <div className="-m-1 flex flex-wrap md:-m-2">

          <div className="flex justify-center items-start w-full flex-wrap">
            {temple.map((item) => {
              const { id, title, image } = item;
              return (
                <div className="m-3">
                  <div
                    key={id}
                    className="block justify-center w-64 rounded-lg object-center"
                  >
                    <img
                      src={image || "https://picsum.photos/200"}
                      alt={title}
                      className=""
                      style={{ height: "220px", width: "100%" }}
                    />
                    <div class="desc-dynamic">{title}</div>
                    {/* <h2 className="text-xl font-bold text-center">{title}</h2> */}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Saitemple;
