import {
  hero,
  hero2,
  aarti,
  accomodation,
  darshan,
  pooja,
  publications,
  bimg,
  room1,
  room2,
  room3,
  room4,
  room5,
  room6,
  gal1,
  gal2,
  gal3,
  opd,
  Her,
  con,
  med,
  vastra,
  edu,
  camp,
  GDD,
  GM,
  ASB,
  PrK,
  SKG,
  SSRKJ,
  SSS,
  TDM,
} from "../img";

const data = [
  {
    hero: [
      {
        id: 1,
        title: "Shri Sai Bhakta Samaj",
        img: `${hero}`,
      },
      {
        id: 2,
        title: "Shri Sai Bhakta Samaj",
        img: `${hero2}`,
      },
      {
        id: 3,
        title: "Shri Sai Bhakta Samaj",
        img: `${hero}`,
      },
      {
        id: 4,
        title: "Shri Sai Bhakta Samaj",
        img: `${hero2}`,
      },
    ],
    rooms: [
      {
        id: 1,
        title: "Shri Sai Bhakta Samaj",
        img: `${room1}`,
      },
      {
        id: 2,
        title: "Shri Sai Bhakta Samaj",
        img: `${room2}`,
      },
      {
        id: 3,
        title: "Shri Sai Bhakta Samaj",
        img: `${room3}`,
      },
      {
        id: 4,
        title: "Shri Sai Bhakta Samaj",
        img: `${room4}`,
      },
      {
        id: 5,
        title: "Shri Sai Bhakta Samaj",
        img: `${room5}`,
      },
      {
        id: 6,
        title: "Shri Sai Bhakta Samaj",
        img: `${room6}`,
      },
    ],
    about: [
      {
        id: 1,
        title: "About us",
        text: "Shri Sai Bhakta Samaj (Regd). was established in October 1968 with its Regd. Office at 27, Rajpur Road, Delhi being the residence of the then President Mr. R.S. Chitnis alongwith the following office bearers and members of Managing Committee with the noble objects to spread Baba's name/teaching amongst the public in General and devotees of Shri Sai Baba in particular, the spirit of universal brotherhood or religious toleration as embodied in Sai Bhakti, high principles of conduct and character without distinction of caste, creed and religion through mandir amongst other objects such as publishing magazines, periodicals, books etc. To promote and carry out services of all kinds which Holy SAI BABA had rendered to humanity. To establish libraries, regional centers, hospitals, Dharamshalas to help the poor and distressed to relieve human sufferings and improve standard of living and conduct. To promote unity among people belonging to different religions, caste and creed and work towards national integration with the following as office bearers and members of the 1st Managing Committee.",
        btn: "Read More",
        link: "/aboutus",
        img: "https://picsum.photos/200/300",
      },
    ],
    person: [
      {
        id: 1,
        title: "President",
        name: "Mr. Sushil Kapoor",
        img: "https://picsum.photos/200/300",
      },
      {
        id: 2,
        title: "Sr. Vice President",
        name: "Mr. R.K. Oberoi",
        img: "https://picsum.photos/200/300",
      },
      {
        id: 3,
        title: "Jr. Vice President",
        name: "Mr. T.D. Monga",
        img: "https://picsum.photos/200/300",
      },
      {
        id: 4,
        title: "Joint Secretary",
        name: "Mrs. Shalini Uppal",
        img: "https://picsum.photos/200/300",
      },
      {
        id: 5,
        title: "Treasurer",
        name: "Mr. K.M. Bhatnagar",
        img: "https://picsum.photos/200/300",
      },
      {
        id: 6,
        title: "Joint Treasurer",
        name: "Mr. S.K. Gupta",
        img: "https://picsum.photos/200/300",
      },
    ],
    mmc: [
      {
        id: 1,
        title: "Vice President",
        name: "Mr. Sushil Kumar Kapoor",
        img: "https://picsum.photos/200/300",
        info: "President",
      },
      {
        id: 2,
        title: "Sr. Vice President",
        name: "Mr. R.K. Oberoi",
        img: "https://picsum.photos/200/300",
        info: "Sr. Vice President",
      },
      {
        id: 3,
        title: "Jr. Vice President",
        name: "Mr. T.D. Monga",

        img: `${TDM}`,
        info: "Jr. Vice President",
      },
      {
        id: 4,
        title: "Hony. Secretary",
        name: "Mr. R.K. Chopra",
        img: "https://picsum.photos/200/300",
        info: "Hony. Secretary",
      },
      {
        id: 5,
        title: "officiating secretariat",
        name: "Smt. Shalini Uppal",
        img: "https://picsum.photos/200/300",
        info: "officiating secretariat",
      },
      {
        id: 6,
        title: "Treasurer",
        name: "Mr. K.M. Bhatnagar",
        img: "https://picsum.photos/200/300",
        info: "Treasurer",
      },
      {
        id: 7,
        title: "Joint Treasurer",
        name: "Mr. S.K. Gupta",
        img: `${SKG}`,
        info: "Joint Treasurer",
      },
      {
        id: 8,
        title: "Managing Committee Member",
        name: "Mr. A.S.C. Sinha",
        img: "https://picsum.photos/200/300",
        info: "Managing Committee Member",
      },
      {
        id: 9,
        title: "Managing Committee Member",
        name: "Mr. Arun Sagar Bhatia",
        img: `${ASB}`,
        info: "Managing Committee Member",
      },
      {
        id: 10,
        title: "Managing Committee Member",
        name: "Mr. B.M.S. Bhatnaagar",
        img: "https://picsum.photos/200/300",
        info: "Managing Committee Member",
      },
      {
        id: 11,
        title: "Managing Committee Member",
        name: "Mr. Chander Prakash Anand",
        img: "https://picsum.photos/200/300",
        info: "Managing Committee Member",
      },
      {
        id: 12,
        title: "Managing Committee Member",
        name: "Mr. Davinder Khanna",
        img: "https://picsum.photos/200/300",
        info: "Managing Committee Member",
      },
      {
        id: 13,
        title: "Managing Committee Member",
        name: "Mr. Deepak Dhawan",
        img: "https://picsum.photos/200/300",
        info: "Managing Committee Member",
      },
      {
        id: 14,
        title: "Managing Committee Member",
        name: "Dr Sanjiv Kumar",
        img: "https://picsum.photos/200/300",
        info: "Managing Committee Member",
      },
      {
        id: 15,
        title: "Managing Committee Member",
        name: "Mr. Gajadhar Dubey",
        img: `${GDD}`,
        info: "Managing Committee Member",
      },
      {
        id: 16,
        title: "Managing Committee Member",
        name: "Mr. Gaurav Mishra",
        img: `${GM}`,
        info: "Managing Committee Member",
      },
      {
        id: 17,
        title: "Managing Committee Member",
        name: "Mr. Harish Dhawan",
        img: "https://picsum.photos/200/300",
        info: "Managing Committee Member",
      },
      {
        id: 18,
        title: "Managing Committee Member",
        name: "Mr. Kewal Krishan Gakhar",
        img: "https://picsum.photos/200/300",
        info: "Managing Committee Member",
      },
      {
        id: 19,
        title: "Managing Committee Member",
        name: "Mr. Mahesh chandra Sharma",
        img: "https://picsum.photos/200/300",
        info: "Managing Committee Member",
      },
      {
        id: 20,
        title: "Managing Committee Member",
        name: "Mr. Pradeep Khanna",
        img: `${PrK}`,
        info: "Managing Committee Member",
      },
      {
        id: 21,
        title: "Managing Committee Member",
        name: "Mr. R.P. Sharma(Ram Prakash Sharma)",
        img: "https://picsum.photos/200/300",
        info: "Managing Committee Member",
      },
      {
        id: 22,
        title: "Managing Committee Member",
        name: "Mr. Raj Kumar Jaiswal",
        img: `${SSRKJ}`,
        info: "Managing Committee Member",
      },
      {
        id: 23,
        title: "Managing Committee Member",
        name: "Mr. Sahib Singh",
        img: `${SSS}`,
        info: "Managing Committee Member",
      },
      {
        id: 24,
        title: "Managing Committee Member",
        name: "Mr. Subhsh Uppal",
        img: "https://picsum.photos/200/300",
        info: "Managing Committee Member",
      },
      {
        id: 25,
        title: "Managing Committee Member",
        name: "Mr. Sunil Kumar",
        img: "https://picsum.photos/200/300",
        info: "Managing Committee Member",
      },
      {
        id: 26,
        title: "Managing Committee Member",
        name: "Mr. Vinod Gupta",
        img: "https://picsum.photos/200/300",
        info: "Managing Committee Member",
      },
    ],
    notification: [
      {
        id: 1,
        category: "Latest News",
        title: "Sai baba Lodhi road.",
        text: "Sai Baba Mandir Lodhi Road is one of the oldest temples in Delhi. The temple is located on Lodhi road and is present roadside. Many devotees pay their visits to this temple. The maximum rush can be seen on Thursdays when people pay their offerings and seek peace. The temple is maintained by Shri Sai Bhakt Samaj, Delhi. The temple is built in modern style and is built along the roadside. Due to this reason, many devotees offer their pranams to Sai Baba while passing through the road. One can see the idol of Sai Baba from the road. The idol is built with white marble. The temple has air conditioners installed in the main hall and a golden crown embellishing the main statue. The temple is quite simple from outside but majestic inside.I hope this helps you learn more about Sai Baba Temple Lodhi Road!",
        date: "20/05/2023",
        link: "#",
        img: `${aarti}`,
      },
      {
        id: 2,
        category: "Article",
        title: "Sai baba Lodhi road.",
        text: "Sai Baba Mandir Lodhi Road is one of the oldest temples in Delhi. The temple is located on Lodhi road and is present roadside. Many devotees pay their visits to this temple. The maximum rush can be seen on Thursdays when people pay their offerings and seek peace. The temple is maintained by Shri Sai Bhakt Samaj, Delhi. The temple is built in modern style and is built along the roadside. Due to this reason, many devotees offer their pranams to Sai Baba while passing through the road. One can see the idol of Sai Baba from the road. The idol is built with white marble. The temple has air conditioners installed in the main hall and a golden crown embellishing the main statue. The temple is quite simple from outside but majestic inside.I hope this helps you learn more about Sai Baba Temple Lodhi Road!",
        date: "20/05/2023",
        link: "#",
        img: `${darshan}`,
      },
      {
        id: 3,
        category: "Sai baba",
        title: "Sai Bhakta Samaj Lodhi road.",
        text: "Sai Baba Mandir Lodhi Road is one of the oldest temples in Delhi. The temple is located on Lodhi road and is present roadside. Many devotees pay their visits to this temple. The maximum rush can be seen on Thursdays when people pay their offerings and seek peace. The temple is maintained by Shri Sai Bhakt Samaj, Delhi. The temple is built in modern style and is built along the roadside. Due to this reason, many devotees offer their pranams to Sai Baba while passing through the road. One can see the idol of Sai Baba from the road. The idol is built with white marble. The temple has air conditioners installed in the main hall and a golden crown embellishing the main statue. The temple is quite simple from outside but majestic inside.I hope this helps you learn more about Sai Baba Temple Lodhi Road!",
        date: "20/05/2023",
        link: "#",
        img: `${pooja}`,
      },
    ],
    appeal: [
      {
        id: 1,
        title: "Latest News",
        text: "Devotees are hereby informed that make donations in the Donation",
        link: "#",
        download: "#",
        img: "https://picsum.photos/200/300",
      },
      {
        id: 2,
        title: "Latest News",
        text: "Devotees are hereby informed that make donations in the Donation",
        link: "#",
        download: "#",
        img: "https://picsum.photos/200/300",
      },
    ],
    gallery: [
      {
        id: 1,
        img: `${aarti}`,
        title: "Aarti",
        link: "#",
      },
      {
        id: 2,
        img: `${darshan}`,
        title: "Darshan",
        link: "#",
      },
      {
        id: 3,
        img: `${pooja}`,
        title: "Pooja",
        link: "#",
      },
      {
        id: 4,
        img: `${accomodation}`,
        title: "Accomodation",
        link: "#",
      },
      {
        id: 5,
        img: `${publications}`,
        title: "Publications",
        link: "#",
      },
      {
        id: 6,
        img: `${aarti}`,
        title: "Aarti",
        link: "#",
      },
      {
        id: 7,
        img: `${aarti}`,
        title: "Aarti",
        link: "#",
      },
      {
        id: 8,
        img: `${darshan}`,
        title: "Darshan",
        link: "#",
      },
      {
        id: 9,
        img: `${pooja}`,
        title: "Pooja",
        link: "#",
      },
      {
        id: 10,
        img: `${accomodation}`,
        title: "Accomodation",
        link: "#",
      },
      {
        id: 11,
        img: `${publications}`,
        title: "Publications",
        link: "#",
      },
      {
        id: 12,
        img: `${aarti}`,
        title: "Aarti",
        link: "#",
      },
      {
        id: 13,
        img: `${gal1}`,
        title: "Aarti",
        link: "#",
      },
      {
        id: 14,
        img: `${gal2}`,
        title: "Aarti",
        link: "#",
      },
      {
        id: 15,
        img: `${gal3}`,
        title: "Aarti",
        link: "#",
      },
    ],
    temple: [
      {
        id: 1,
        title: "O.P.D",
        img: `${opd}`,
      },
      {
        id: 3,
        title: "Construction of Sai Vishram Sadan at AIIMS",
        img: `${con}`,
      },
      {
        id: 4,
        title: "Medical Aid",
        img: `${med}`,
      },
      {
        id: 5,
        title: "Distribution Of Baba Vastras",
        img: `${vastra}`,
      },
      {
        id: 6,
        title: "Education",
        img: `${edu}`,
      },
      {
        id: 7,
        title: "Camps",
        img: `${camp}`,
      },
    ],
    d_programme: [
      {
        id: 1,
        w_time: "5.30 a.m.",
        s_time: "5.00 a.m.",
        title: "Temple Opens",
        link: "#",
      },
      {
        id: 2,
        w_time: "5.30 a.m.",
        s_time: "5.30 a.m",
        title: "Kakad Aarti",
        link: "#",
      },
      {
        id: 3,
        w_time: "5.30 a.m.",
        s_time: "6.00 a.m",
        title: "Mangal SNAN",
        link: "#",
      },
      {
        id: 4,
        w_time: "5.30 a.m.",
        s_time: "8.00 a.m",
        title: "Aarti at Morning",
        link: "#",
      },
      {
        id: 5,
        w_time: "5.30 a.m.",
        s_time: "12 noon",
        title: "Noon Aarti",
        link: "#",
      },
      {
        id: 6,
        w_time: "5.30 a.m.",
        s_time: "6.30 p.m",
        title: "Dhoop Aarti Evening Prayer",
        link: "#",
      },
      {
        id: 7,
        w_time: "5.30 a.m.",
        s_time: "9.30 p.m",
        title: "Shej Aarti at Night",
        link: "#",
      },
      {
        id: 8,
        w_time: "5.30 a.m.",
        s_time: "7.00 PM",
        title: "Thursday Dhoop Aarti Evening Prayer",
        link: "#",
      },
    ],
    l_updates: [
      {
        id: 1,
        title: "Shri Saibaba Sansthan Trust, Shirdi",
        text: "Shri Saibaba Sansthan Trust, Shirdi is pleased to launch next generation portal for availing Darshan, Aarti, Donation, Accommodation, Pooja, Membership and Publications services. Please click on the below links to avail the services.",
        btn: "Read More",
        link: "#",
        img: "https://picsum.photos/200/300",
      },

      {
        id: 2,
        title: "Shri Saibaba Sansthan Trust, Shirdi",
        text: "Shri Saibaba Sansthan Trust, Shirdi is pleased to launch next generation portal for availing Darshan, Aarti, Donation, Accommodation, Pooja, Membership and Publications services. Please click on the below links to avail the services.",
        btn: "Read More",
        link: "#",
        img: "https://picsum.photos/200/300",
      },
      {
        id: 3,
        title: "Shri Saibaba Sansthan Trust, Shirdi",
        text: "Shri Saibaba Sansthan Trust, Shirdi is pleased to launch next generation portal for availing Darshan, Aarti, Donation, Accommodation, Pooja, Membership and Publications services. Please click on the below links to avail the services.",
        btn: "Read More",
        link: "#",
        img: "https://picsum.photos/200/300",
      },
      {
        id: 4,
        title: "Shri Saibaba Sansthan Trust, Shirdi",
        text: "Shri Saibaba Sansthan Trust, Shirdi is pleased to launch next generation portal for availing Darshan, Aarti, Donation, Accommodation, Pooja, Membership and Publications services. Please click on the below links to avail the services.",
        btn: "Read More",
        link: "#",
        img: "https://picsum.photos/200/300",
      },
    ],
    booking: [
      {
        id: 1,
        title: "Accomodation",
        text: "Devotees are hereby informed that make donations in the Donation",
        link: "/booking",
        img: `${bimg}`,
        location: "#",
        youtube: "#",
        dft: "1.7 km.",
        dfbs: "2.5 km.",
        dfrs: "2.8 km.",
      },
    ],
    r_filter: [
      {
        id: 1,
        title: "Ac",
        category: "Ac",
        cap: "3",
        a_rooms: "58",
        rate: "300",
      },
      {
        id: 2,
        title: "Non Ac",
        category: "Non Ac",
        cap: "3",
        a_rooms: "58",
        rate: "200",
      },
      {
        id: 3,
        title: "Ac",
        category: "Ac",
        cap: "3",
        a_rooms: "58",
        rate: "300",
      },
      {
        id: 4,
        title: "Non Ac",
        category: "Non Ac",
        cap: "3",
        a_rooms: "58",
        rate: "200",
      },
      {
        id: 5,
        title: "Ac",
        category: "Ac",
        cap: "3",
        a_rooms: "58",
        rate: "300",
      },
    ],
    tabs: [
      {
        id: 1,
        title: "Accomodation",
        gs: [
          {
            id: 1,
            head: "",
            title:
              "Rooms Available For Booking In Shirdi On Counter Of Each Building.",
            loc: "Shri Sai Bhakta Nivash",
            sr_n: "01",
            r_type: "General",
            r_desc: "3 beds attached non AC rooms",
            min_cap: 3,
            max_cap: 5,
            rate: 200,
          },
          {
            id: 2,
            head: "",
            title:
              "Rooms Available For Booking In Shirdi On Counter Of Each Building.",
            loc: "Shri Sai Bhakta Nivash",
            sr_n: "02",
            r_type: "General",
            r_desc: "3 beds attached non AC rooms",
            min_cap: 3,
            max_cap: 5,
            rate: 200,
          },
          {
            id: 3,
            head: "",
            title:
              "Rooms Available For Booking In Shirdi On Counter Of Each Building.",
            loc: "Shri Sai Bhakta Nivash",
            sr_n: "03",
            r_type: "General",
            r_desc: "3 beds attached non AC rooms",
            min_cap: 3,
            max_cap: 5,
            rate: 200,
          },
        ],
        ac: [
          {
            id: 1,
            head: "",
            title:
              "Rooms Available For Booking In Shirdi On Counter Of Each Building.",
            loc: "Shri Sai Bhakta Nivash",
            sr_n: "01",
            r_type: "Ac",
            r_desc: "3 beds attached non AC rooms",
            min_cap: 3,
            max_cap: 5,
            rate: 200,
          },
          {
            id: 2,
            head: "",
            title:
              "Rooms Available For Booking In Shirdi On Counter Of Each Building.",
            loc: "Shri Sai Bhakta Nivash",
            sr_n: "02",
            r_type: "Ac",
            r_desc: "3 beds attached non AC rooms",
            min_cap: 3,
            max_cap: 5,
            rate: 200,
          },
          {
            id: 3,
            head: "",
            title:
              "Rooms Available For Booking In Shirdi On Counter Of Each Building.",
            loc: "Shri Sai Bhakta Nivash",
            sr_n: "03",
            r_type: "Ac",
            r_desc: "3 beds attached non AC rooms",
            min_cap: 3,
            max_cap: 5,
            rate: 200,
          },
        ],
        n_ac: [
          {
            id: 1,
            head: "",
            title:
              "Rooms Available For Booking In Shirdi On Counter Of Each Building.",
            loc: "Shri Sai Bhakta Nivash",
            sr_n: "01",
            r_type: "Non-Ac",
            r_desc: "3 beds attached non AC rooms",
            min_cap: 3,
            max_cap: 5,
            rate: 200,
          },
          {
            id: 2,
            head: "",
            title:
              "Rooms Available For Booking In Shirdi On Counter Of Each Building.",
            loc: "Shri Sai Bhakta Nivash",
            sr_n: "02",
            r_type: "Non-Ac",
            r_desc: "3 beds attached non AC rooms",
            min_cap: 3,
            max_cap: 5,
            rate: 200,
          },
          {
            id: 3,
            head: "",
            title:
              "Rooms Available For Booking In Shirdi On Counter Of Each Building.",
            loc: "Shri Sai Bhakta Nivash",
            sr_n: "03",
            r_type: "Non-Ac",
            r_desc: "3 beds attached non AC rooms",
            min_cap: 3,
            max_cap: 5,
            rate: 200,
          },
        ],
      },
    ],
    a_time: [
      {
        id: 1,
        time: "12:00 pm",
      },
      {
        id: 2,
        time: "01:00 pm",
      },
      {
        id: 3,
        time: "02:00 pm",
      },
      {
        id: 4,
        time: "03:00 pm",
      },
      {
        id: 5,
        time: "04:00 pm",
      },
      {
        id: 6,
        time: "05:00 pm",
      },
      {
        id: 7,
        time: "06:00 pm",
      },
    ],
    d_time: [
      {
        id: 1,
        time: "08:00 am",
      },
      {
        id: 2,
        time: "09:00 am",
      },
      {
        id: 3,
        time: "10:00 am",
      },
      {
        id: 4,
        time: "11:00 am",
      },
      {
        id: 5,
        time: "12:00 pm",
      },
      {
        id: 6,
        time: "01:00 pm",
      },
      {
        id: 7,
        time: "02:00 pm",
      },
    ],
  },
];

export default data;
