import React from "react";
import data from "../components/data";
import { Link } from "react-router-dom";

const News = () => {
  const news = data[0].notification;
  return (
    <div>
      <section className="pt-20 lg:pt-[40px] pb-10 lg:pb-10">
        <div className="container">
          <div className="flex flex-wrap justify-center -mx-4">
            <div className="w-full px-4">
              <div className="text-center mx-auto  lg:mb-10 max-w-[510px]">
                {/* <span className="font-semibold text-lg text-primary mb-2 block">
                  Our News
                </span> */}

                <h1 class="text-3xl text-heading font-bold pb-7"><span class="text-secondary ld-fh-txt all-text-gradient">Our Recent News</span></h1>

              </div>
            </div>
          </div>
          <div className="flex flex-wrap -mx-4">
            {news.map((item, index) => {
              return (
                <div className="w-full md:w-1/2 lg:w-1/3 px-4" key={index}>
                  <div className="max-w-[370px] mx-auto mb-10">
                    <div className="rounded overflow-hidden mb-8">
                      <img src={item.img} alt={item.title} className="w-full" />
                    </div>
                    <div>
                      <span className=" bg-gradient-to-r from-btn-from to-btn-to rounded inline-block text-center py-1 px-4 text-xs leading-loose font-semibold text-white mb-5 ">
                        {item.date}
                      </span>
                      <h3>
                        <Link
                          to={item.link}
                          className="font-semibold  text-xl sm:text-2xl lg:text-xl xl:text-2xl mb-4 inline-block text-dark hover:text-primary "
                        >
                          {item.title}
                        </Link>
                      </h3>
                      <p className="text-base text-body-color line-clamp-3">
                        {item.text}
                      </p>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </section>
    </div>
  );
};

export default News;
