import React, { useState, useEffect } from "react";
import axios from "axios";
import data from "./data";

export const Tabs = () => {
  const t_room = data[0].tabs[0];
  return (
    <div className="">
      <ul
        className="nav nav-pills mb-3 flex flex-nowrap justify-between max-sm:justify-center bg-gray-500 text-gray-900 rounded"
        id="pills-tab"
        role="tablist"
      >
        <li className="nav-item w-50" role="presentation">
          <button
            className="nav-link text-xl active lg:px-10 md:px-5 sm:px-2 py-3 w-full"
            id="summer-tab"
            data-bs-toggle="pill"
            data-bs-target="#ac"
            type="button"
            role="tab"
            aria-controls="ac"
            aria-selected="false"
          >
            Ac
          </button>
        </li>
        <li className="nav-item w-50" role="presentation">
          <button
            className="nav-link text-xl md:px-10 sm:px-2 py-3 w-full"
            id="winter-tab"
            data-bs-toggle="pill"
            data-bs-target="#winter"
            type="button"
            role="tab"
            aria-controls="winter"
            aria-selected="false"
          >
            Non-Ac
          </button>
        </li>
      </ul>
      <div className="tab-content" id="pills-tabContent">
        <div
          className="tab-pane fade show active"
          id="ac"
          role="tabpanel"
          aria-labelledby="summer-tab"
          tabIndex="0"
        >
          <h1 className="text-heading font-bold pb-5">
            A.C. Rooms Available For Online Booking & Local Booking
          </h1>
          <div className=" overflow-x-scroll ">
            <table className="table  table-responsive w-100">
              <thead>
                <tr>
                  <th>Room No</th>
                  <th>Room Type</th>
                  <th>Room Rent</th>
                  <th>Min Cap</th>
                  <th>Max Cap</th>
                  <th>Price</th>
                </tr>
              </thead>
              <tbody>
                {t_room.ac.map((acroom) => {
                  return (
                    <tr key={acroom.id}>
                      <td>{acroom.sr_n}</td>
                      <td>{acroom.r_type}</td>
                      <td>{acroom.r_desc}</td>
                      <td>{acroom.min_cap}</td>
                      <td>{acroom.max_cap}</td>
                      <td>{acroom.rate}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
        <div
          className="tab-pane fade"
          id="winter"
          role="tabpanel"
          aria-labelledby="winter-tab"
          tabIndex="0"
        >
          <h1 className="text-heading font-bold pb-5">
            Non A.C. Rooms Available For Online Booking & Local Booking
          </h1>
          <div className=" overflow-x-scroll ">
            <table className="table  table-responsive w-100">
              <thead>
                <tr>
                  <th>Room No</th>
                  <th>Room Type</th>
                  <th>Room Rent</th>
                  <th>Min Cap</th>
                  <th>Max Cap</th>
                  <th>Price</th>
                </tr>
              </thead>
              <tbody>
                {t_room.n_ac.map((n_acroom) => {
                  return (
                    <tr key={n_acroom.id}>
                      <td>{n_acroom.sr_n}</td>
                      <td>{n_acroom.r_type}</td>
                      <td>{n_acroom.r_desc}</td>
                      <td>{n_acroom.min_cap}</td>
                      <td>{n_acroom.max_cap}</td>
                      <td>{n_acroom.rate}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export const DailyTabs = () => {
  const [dPro, setDPro] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const result = await axios(
        "https://nimittech.com/sai_api/daily_program.php"
      );

      setDPro(result.data);
    };

    fetchData();
  }, []);

  return (
    <div className="">
      <ul
        className="static nav nav-pills mb-3 flex flex-nowrap justify-between max-sm:justify-center bg-[#dbdbdb] text-gray-900"
        id="pills-tab"
        role="tablist"
      >
        <li className="nav-item w-50" role="presentation">
          <button
            className="nav-link text-base text-gray-800 font-bold hover:text-heading active lg:px-10 md:px-5 sm:px-2 py-1 w-full"
            id="summer-tab"
            data-bs-toggle="pill"
            data-bs-target="#summer"
            type="button"
            role="tab"
            aria-controls="summer"
            aria-selected="false"
          >
            Summer
          </button>
        </li>
        <li className="nav-item w-50" role="presentation">
          <button
            className="nav-link text-base text-gray-800 font-bold hover:text-heading md:px-10 sm:px-2 py-1 w-full"
            id="winter-tab"
            data-bs-toggle="pill"
            data-bs-target="#winter"
            type="button"
            role="tab"
            aria-controls="winter"
            aria-selected="false"
          >
            Winter
          </button>
        </li>
      </ul>
      <div className="tab-content" id="pills-tabContent">
        <div
          className="tab-pane fade show active"
          id="summer"
          role="tabpanel"
          aria-labelledby="summer-tab"
          tabIndex="0"
        >
          <div className=" overflow-x-scroll ">
            <table className="table text-center break-words table-responsive w-100">
              <thead>
                <tr>
                  <th>Programme Name</th>
                  <th>Time</th>
                </tr>
              </thead>
              <tbody>
                {dPro.map((sum) => {
                  return (
                    <tr key={sum.id}>
                      <td>{sum.title}</td>
                      <td>{sum.summer_time}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
        <div
          className="tab-pane fade"
          id="winter"
          role="tabpanel"
          aria-labelledby="winter-tab"
          tabIndex="0"
        >
          <div className=" overflow-x-scroll ">
            <table className="table  table-responsive w-100">
              <thead>
                <tr>
                  <th>Programme Name</th>
                  <th>Time</th>
                </tr>
              </thead>
              <tbody>
                {dPro.map((win) => {
                  return (
                    <tr key={win.id}>
                      <td>{win.title}</td>
                      <td>{win.winter_time}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};
