import { useState, useRef } from "react";
import { BsFillPersonFill } from "react-icons/bs";
import { MdEmail } from "react-icons/md";
import { RiLockPasswordFill } from "react-icons/ri";
import { Redirect } from "react-router-dom";
import axios from "axios";
import emailjs from "@emailjs/browser";

const Signup = () => {
  const form = useRef();

  const [signup, setSignup] = useState("");
  const [fullname, setFullname] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [mobile, setMobile] = useState("");
  const [pending, setPending] = useState(false);

  const URL = "https://nimittech.com/loginapi/registerapi.php";
  const notification = "../API/notification.php";

  const handlefullnameChange = (event) => {
    setFullname(event.target.value);
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };
  const handleMobileChange = (event) => {
    setMobile(event.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setPending(true);

    const registerData = {
      fullname,
      email,
      password,
      mobile,
    };

    // Make the API request using fetch
    // fetch(URL, {
    //   method: "POST",
    //   headers: {
    //     "Content-Type": "application/json",
    //   },
    //   body: JSON.stringify(registerData),
    // })
    //   .then((response) => response.json())
    //   .then((response) => {
    //     if (response.success) {
    //       console.log("Signup successful!");
    //     } else {
    //       console.log("Signup failed:", response.error);
    //     }
    //     console.log(response.data);
    //     // Handle the response data here
    //     console.log(response);
    //   })
    //   .catch((error) => {
    //     // Handle any errors here
    //     console.error(error);
    // });

    // If you prefer to use Axios:
    axios
      .post(URL, registerData, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        if (response.success) {
          console.log("Signup successful!");
        } else {
          console.log("Signup failed:", response.error);
        }
      })
      .catch((error) => {
        console.error(error);
      });

    // emailjs
    //   .sendForm(
    //     "service_9jfkoiq",
    //     "template_ucy0r1s",
    //     form.current,
    //     "pnMMpWkob3bfgMszM"
    //   )
    //   .then(
    //     (result) => {
    //       setPending(false);
    //       console.log(result.text);
    //       alert(`Thank you ${fullname} for joining Shri Sai Bhakta Samaj`);
    //       form.current.reset();
    //     },
    //     (error) => {
    //       setPending(false);
    //       console.log(error.text);
    //       alert("Something went wrong. Please try again later.");
    //     }
    //   );
  };

  return (
    <div>
      <div className="container lg:w-1/2 mx-auto py-5">
        <div className="bg-gray-300 text-gray-800 rounded-3xl shadow-xl w-full overflow-hidden">
          <div className="flex justify-center w-full">
            <div className="flex-row justify-center items-center py-5 px-5 md:px-10">
              <div className="text-center mb-3">
                <h1 className="font-bold text-3xl text-gray-700">REGISTER</h1>
                <p className="text-gray-700">
                  Enter your information to register
                </p>
              </div>
              <form onSubmit={handleSubmit} ref={form}>
                <div>
                  <div className="flex -mx-3">
                    <div className="w-full px-3 mb-3">
                      <label
                        for="fullname"
                        className="block text-gray-700 font-bold mb-1 md:mb-0 pr-4 text-left"
                      >
                        Full Name
                      </label>
                      <div className="flex">
                        <div className="w-10 z-10 pl-1 text-center pointer-events-none flex items-center justify-center">
                          <BsFillPersonFill className="mdi mdi-account-outline text-gray-600 text-lg" />
                        </div>
                        <input
                          type="text"
                          id="fullname"
                          name="fullname"
                          value={fullname}
                          onChange={handlefullnameChange}
                          className="w-full -ml-10 pl-10 pr-3 py-2 rounded-lg border-2 border-gray-200 outline-none focus:border-gray-700"
                          required
                          placeholder="John"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="flex -mx-3">
                    <div className="w-full px-3 mb-3">
                      <label
                        for="email"
                        className="block text-gray-700 font-bold mb-1 md:mb-0 pr-4 text-left"
                      >
                        Email
                      </label>
                      <div className="flex">
                        <div className="w-10 z-10 pl-1 text-center pointer-events-none flex items-center justify-center">
                          <MdEmail className="mdi mdi-account-outline text-gray-600 text-lg" />
                        </div>
                        <input
                          type="email"
                          id="email"
                          name="email"
                          value={email}
                          onChange={handleEmailChange}
                          className="w-full -ml-10 pl-10 pr-3 py-2 rounded-lg border-2 border-gray-200 outline-none focus:border-gray-700"
                          required
                          placeholder="johnsmith@example.com"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="flex -mx-3">
                    <div className="w-full px-3 mb-3">
                      <label
                        for="mobile"
                        className="block text-gray-700 font-bold mb-1 md:mb-0 pr-4 text-left"
                      >
                        Mobile no.
                      </label>
                      <div className="flex">
                        <div className="w-10 z-10 pl-1 text-center pointer-events-none flex items-center justify-center">
                          <MdEmail className="mdi mdi-account-outline text-gray-600 text-lg" />
                        </div>
                        <input
                          type="number"
                          id="mobile"
                          name="mobile"
                          value={mobile}
                          onChange={handleMobileChange}
                          className="w-full -ml-10 pl-10 pr-3 py-2 rounded-lg border-2 border-gray-200 outline-none focus:border-gray-700"
                          required
                          placeholder="johnsmith@example.com"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="flex -mx-3">
                    <div className="w-full px-3 mb-3">
                      <label
                        for="password"
                        className="block text-gray-700 font-bold mb-1 md:mb-0 pr-4 text-left"
                      >
                        Password
                      </label>
                      <div className="flex">
                        <div className="w-10 z-10 pl-1 text-center pointer-events-none flex items-center justify-center">
                          <RiLockPasswordFill className="mdi mdi-account-outline text-gray-600 text-lg" />
                        </div>
                        <input
                          type="password"
                          id="password"
                          name="password"
                          value={password}
                          onChange={handlePasswordChange}
                          className="w-full -ml-10 pl-10 pr-3 py-2 rounded-lg border-2 border-gray-200 outline-none focus:border-gray-700"
                          required
                          placeholder="************"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="flex -mx-3">
                    <div className="w-full px-3">
                      <button
                        type="submit"
                        disabled={pending ? true : false}
                        className="block w-full max-w-xs mx-auto bg-btn-sec hover:bg-nav-gradient-from focus:bg-nav-gradient-from text-white rounded-lg px-3 py-3 font-semibold"
                      >
                        REGISTER NOW
                      </button>
                    </div>
                  </div>
                </div>
              </form>
              <div className="flex items-center justify-center pt-3">
                <p className="block text-gray-700 font-bold md:text-right mb-2 md:mb-0 pr-3">
                  Allready have an account ?
                </p>
                <p className="block text-gray-700 underline font-bold md:text-right mb-2 md:mb-0">
                  <a href="/login" className="text-gray-900 hover:text-heading">
                    Sign in
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Signup;
