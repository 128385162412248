import React, { useState, useEffect, useRef } from "react";
import { Login } from "./index";
import Filterrooms from "../components/Filterrooms";
import axios from "axios";
import DatePicker from "react-date-picker";
import "react-date-picker/dist/DatePicker.css";
import "react-calendar/dist/Calendar.css";
import Paymentpage from "./Paymentpage";
import data from "./../components/data";
import emailjs from "@emailjs/browser";

const AccommodationBookingForm = ({ handleNext }) => {
  const form = useRef();
  const [seats, setSeats] = useState(15);
  const [type, setType] = useState(false);
  const [storeType, setStoreType] = useState();
  const [pending, setPending] = useState();
  const [bookingData, setBookingData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    phone_no: "",
    street: "",
    city: "",
    state: "",
    pin_code: "",
    arrival_date: "",
    arrival_time: "",
    no_of_person: "",
    room_type: "",
    message: "",
    adhaar_no: "",
    adhaar_card: "",
  });

  const dataPrice = () => {
    const price = storeType;
    return price;
  };

  const pric = dataPrice();

  const URL = "https://nimittech.com/sai_api/booking.php";

  const [date, setDate] = useState(new Date());

  const data = {
    ...bookingData,
    arrival_date: date.toLocaleDateString(),
  };

  const resetForm = () => {
    setBookingData({
      first_name: "",
      last_name: "",
      email: "",
      phone_no: "",
      street: "",
      city: "",
      state: "",
      pin_code: "",
      arrival_date: "",
      arrival_time: "",
      no_of_person: "",
      room_type: "",
      message: "",
      adhaar_no: "",
      adhaar_card: "",
    });
  };

  const getNextDate = () => {
    const nextDate = new Date(date);
    nextDate.setDate(nextDate.getDate() + 1);
    return nextDate;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setBookingData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    roomTypePrice(e.target.value);
  };

  const roomTypePrice = (e) => {
    if (e === "Ac") {
      setType(true);
      setStoreType("224");
    } else if (e === "Non-Ac") {
      setType(true);
      setStoreType("150");
    }
  };

  const receipt = () => {
    emailjs
      .sendForm(
        "service_9jfkoiq",
        "template_ucy0r1s",
        form.current,
        "pnMMpWkob3bfgMszM"
      )
      .then(
        (result) => {
          setPending(false);
          console.log(result.text);
          alert(
            `Thank you ${bookingData.first_name} ${bookingData.last_name} for your visit at Shri Sai Bhakta Samaj`
          );
          // form.current.reset();
        },
        (error) => {
          setPending(false);
          console.log(error.text);
          alert("Something went wrong. Please try again later.");
        }
      );
  };

  var formBody = [];
  for (var key in data) {
    var encodedKey = encodeURIComponent(key);
    var encodedValue = encodeURIComponent(data[key]);
    formBody.push(encodedKey + "=" + encodedValue);
  }
  formBody = formBody.join("&");

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch(URL, {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
        },
        body: formBody,
      });
      console.log(response);
    } catch (error) {
      console.error(error);
    }
    alert("Booking submitted successfully!");
    resetForm();
    receipt();
    handleNext();
  };

  return (
    <div>
      <div className="">
        <form onSubmit={(e) => handleSubmit(e)} ref={form}>
          <div className="relative flex flex-col md:flex-row justify-center items-center min-w-0 break-words w-full mb-3 text-center">
            <div className="col-6 flex-auto w-full px-4 lg:px-10 py-10 pt-0">
              <h1 className="text-xl font-bold text-black my-3">
                Personal Details
              </h1>
              <div className="w-full">
                <div className="flex max-sm:flex-wrap ">
                  <div className=" w-full mb-3 mx-3">
                    <label
                      className="block uppercase text-left text-gray-700 text-xs font-bold mb-2"
                      htmlFor="full-name"
                    >
                      First Name *
                    </label>
                    <input
                      type="text"
                      name="first_name"
                      value={bookingData.first_name}
                      required
                      onChange={handleChange}
                      className="border-0 px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      placeholder="First Name"
                    />
                  </div>
                  <div className="relative w-full mb-3 mx-3">
                    <label
                      className="block uppercase text-left text-gray-700 text-xs font-bold mb-2"
                      htmlFor="last-name"
                    >
                      Last Name *
                    </label>
                    <input
                      type="text"
                      name="last_name"
                      value={bookingData.last_name}
                      required
                      onChange={handleChange}
                      className="border-0 px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      placeholder="Last Name"
                    />
                  </div>
                </div>
                <div className="flex max-sm:flex-wrap">
                  <div className=" w-full mb-3 mx-3">
                    <label
                      className="block uppercase text-left text-gray-700 text-xs font-bold mb-2"
                      htmlFor="email"
                    >
                      Email *
                    </label>
                    <input
                      type="mail"
                      name="email"
                      value={bookingData.email}
                      required
                      onChange={handleChange}
                      className="border-0 px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      placeholder="mail@example.com"
                    />
                  </div>
                  <div className="relative w-full mb-3 mx-3">
                    <label
                      className="block uppercase text-left text-gray-700 text-xs font-bold mb-2"
                      htmlFor="last-name"
                    >
                      phone Number *
                    </label>
                    <input
                      type="number"
                      name="phone_no"
                      value={bookingData.phone_no}
                      required
                      minLength="10"
                      maxLength="10"
                      onChange={handleChange}
                      className="border-0 px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      placeholder="1234567890"
                    />
                  </div>
                </div>
                <div className="flex max-sm:flex-wrap">
                  <div className=" w-full mb-3 mx-3">
                    <label
                      className="block uppercase text-left text-gray-700 text-xs font-bold mb-2"
                      htmlFor="adhaar_no"
                    >
                      Adhar Card No. *
                    </label>
                    <input
                      type="number"
                      name="adhaar_no"
                      value={bookingData.adhaar_no}
                      required
                      minLength="12"
                      maxLength="12"
                      onChange={handleChange}
                      className="border-0 px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      placeholder="1234-5678-9012"
                    />
                  </div>
                  <div className="relative w-full mb-3 mx-3">
                    <label
                      className="block uppercase text-left text-gray-700 text-xs font-bold mb-2"
                      htmlFor="upload-adhar-card"
                    >
                      Upload Adhar Card *
                    </label>
                    <input
                      type="file"
                      name="adhaar_card"
                      value={bookingData.adhaar_card}
                      accept="image/png, image/jpeg, image/jpg, application/pdf"
                      disabled
                      onChange={handleChange}
                      className="border-0 px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      placeholder="1234567890"
                    />
                  </div>
                </div>

                <div className="flex max-sm:flex-wrap">
                  <div className="relative w-full mb-3 mx-3">
                    <label
                      className="block uppercase text-left text-gray-700 text-xs font-bold mb-2"
                      htmlFor="street"
                    >
                      Street *
                    </label>
                    <input
                      type="text"
                      name="street"
                      value={bookingData.street}
                      required
                      onChange={handleChange}
                      className="border-0 px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      placeholder="street-0"
                    />
                  </div>
                  <div className=" w-full mb-3 mx-3">
                    <label
                      className="block uppercase text-left text-gray-700 text-xs font-bold mb-2"
                      htmlFor="city"
                    >
                      City *
                    </label>
                    <input
                      type="text"
                      name="city"
                      value={bookingData.city}
                      required
                      onChange={handleChange}
                      className="border-0 px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      placeholder="City Name"
                    />
                  </div>
                </div>
                <div className="flex max-sm:flex-wrap">
                  <div className="relative w-full mb-3 mx-3">
                    <label
                      className="block uppercase text-left text-gray-700 text-xs font-bold mb-2"
                      htmlFor="state"
                    >
                      State *
                    </label>
                    <input
                      type="text"
                      name="state"
                      value={bookingData.state}
                      required
                      onChange={handleChange}
                      className="border-0 px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      placeholder="State"
                    />
                  </div>
                  <div className=" w-full mb-3 mx-3">
                    <label
                      className="block uppercase text-left text-gray-700 text-xs font-bold mb-2"
                      htmlFor="pin-code"
                    >
                      pin-code *
                    </label>
                    <input
                      type="number"
                      name="pin_code"
                      value={bookingData.pin_code}
                      required
                      onChange={handleChange}
                      className="border-0 px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      placeholder="123456"
                      minLength="1"
                      maxLength="6"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-6 flex-auto w-full px-4 lg:px-10 py-10 pt-0">
              <h1 className="text-xl font-bold text-black my-3">
                Booking Details
              </h1>
              <div className="w-full">
                <div className="flex max-sm:flex-wrap">
                  <div className=" w-full mb-3 mx-3">
                    <label
                      className="block uppercase text-left text-gray-700 text-xs font-bold mb-2"
                      htmlFor="arrival-date"
                    >
                      Arrival Date *
                    </label>
                    <DatePicker
                      onChange={(date) => setDate(date)}
                      name="arrival_date"
                      value={date}
                      required
                      format="dd/MM/yyyy"
                      minDate={new Date()}
                      clearIcon={null}
                      className={`border-0 px-3 py-3 placeholder-gray-400 text-gray-800 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      placeholder="Arrival Date`}
                    />
                  </div>
                  {/* <div className="relative w-full mb-3 mx-3">
                    <label
                      className="block uppercase text-left text-gray-700 text-xs font-bold mb-2"
                      htmlFor="departure-date"
                    >
                      Arrival Time *
                    </label>
                    <select
                      name="arrival_time"
                      value={bookingData.arrival_time}
                      required
                      onChange={handleChange}
                      className="border-0 px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    >
                      <option value="">Select Time</option>
                      <option value="12:00 pm">12:00 pm</option>
                      <option value="01:00 pm">01:00 pm</option>
                      <option value="02:00 pm">02:00 pm</option>
                      <option value="03:0 pm">03:0 pm</option>
                      <option value="04:00 pm">04:00 pm</option>
                      <option value="05:00 pm">05:00 pm</option>
                      <option value="06:00 pm">06:00 pm</option>
                      <option value="07:00 pm">07:00 pm</option>
                      <option value="08:00 pm">08:00 pm</option>
                    </select>
                  </div> */}
                </div>
                <p className="text-heading pb-3">
                  Your Departure Date & Time is{" : "}
                  {getNextDate().toLocaleDateString()} & 11:00 am.
                </p>
                <div className="flex max-sm:flex-wrap">
                  <div className=" w-full mb-3 mx-3">
                    <label
                      className="block uppercase text-left text-gray-700 text-xs font-bold mb-2"
                      htmlFor="no_of_person"
                    >
                      No. of Person *
                    </label>
                    <input
                      type="number"
                      name="no_of_person"
                      value={bookingData.no_of_person}
                      required
                      onChange={handleChange}
                      min="1"
                      max="5"
                      className="border-0 px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      placeholder="01"
                    />
                  </div>
                  <div className="relative w-full mb-3 mx-3">
                    <label
                      className="block uppercase text-left text-gray-700 text-xs font-bold mb-2"
                      htmlFor="room_type"
                    >
                      Room Type *
                    </label>
                    <select
                      name="room_type"
                      value={bookingData.room_type}
                      required
                      onChange={handleChange}
                      className="border-0 px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    >
                      <option value="">Select</option>

                      <option value="Ac">Ac</option>
                      <option value="Non-Ac">Non-Ac</option>
                    </select>
                  </div>
                </div>
                <div className="relative mb-3 mx-3">
                  <label
                    className="block uppercase text-left text-gray-700 text-xs font-bold mb-2"
                    htmlFor="street"
                  >
                    Message (Optional)
                  </label>
                  <textarea
                    name="message"
                    value={bookingData.message}
                    onChange={handleChange}
                    className="border-0 px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    rows="5"
                    placeholder="Any other request..."
                  />
                </div>
              </div>
              {type && (
                <h3 className=" text-center text-heading pb-3">
                  You have to Pay for booking{" : "}
                  <b>Rs. {pric}</b>.
                </h3>
              )}
            </div>
          </div>
          <div className="text-end px-4">
            <button
              className="bg-gray-900 text-white active:bg-gray-700 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
              type="submit"
              disabled={pending ? true : false}
            >
              Next
            </button>
          </div>
        </form>

        {/*Notes For Booking*/}
        <div className="flex flex-wrap justify-center items-center my-10 ">
          <div className=" flex">
            <div className="text-black px-5">
              <h2 className="text-black font-bold ">Note:</h2>
              <p className="text-black ">- Fields marked * are mandatory.</p>
              <p className="text-black ">
                - Devotee has to report to the specified accommodation center at
                the specified check in time slot.
              </p>
              <p className="text-black ">
                - Quota for the 'Unavailable dates' may be available, in case of
                any cancellations from other devotees.
              </p>
              <p className="text-black ">
                - Devotees availing accommodation any time before 11:00 AM will
                be considered for the same day check-out (11:00 AM) and after
                11:00 AM will be considered for next day check-out based on
                number of days selected.
              </p>
              <p className="text-black ">
                -All the devotee should bring their Authorized/Govt Photo ID
                proof.
              </p>
              <p className="text-black ">
                -For any cancellations before 24 hours no refund will be there,
                you can book for next upcoming availiblity.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const AccommodationFilter = ({ handleNext }) => {
  const handleFilterSubmit = (e) => {
    e.preventDefault();
    // Perform filtering logic
    handleNext();
  };

  return (
    <div>
      <Filterrooms />
      <div className="text-end px-4 py-3">
        <button
          onClick={handleFilterSubmit}
          className="bg-gray-900 text-white active:bg-gray-700 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
          type="submit"
        >
          Next
        </button>
      </div>
      {/*Notes For Booking*/}
      <div className="flex flex-wrap justify-center items-center my-10 ">
        <div className=" flex">
          <div className="text-black px-5">
            <h2 className="text-black font-bold ">Note:</h2>
            <p className="text-black ">- Fields marked * are mandatory.</p>
            <p className="text-black ">
              - Devotee has to report to the specified accommodation center at
              the specified check in time slot.
            </p>
            <p className="text-black ">
              - Quota for the 'Unavailable dates' may be available, in case of
              any cancellations from other devotees.
            </p>
            <p className="text-black ">
              - Devotees availing accommodation any time before 11:00 AM will be
              considered for the same day check-out (11:00 AM) and after 11:00
              AM will be considered for next day check-out based on number of
              days selected.
            </p>
            <p className="text-black ">
              -All the devotee should bring their Authorized/Govt Photo ID
              proof.
            </p>
            <p className="text-black ">
              -For any cancellations before 24 hours no refund will be there,
              you can book for next upcoming availiblity.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

const BookingPage = () => {
  const [step, setStep] = useState(1);

  const handleNext = () => {
    setStep((prevStep) => prevStep + 1);
  };

  return (
    <div>
      <div className="container mx-auto ">
        <div className="flex flex-wrap justify-center items-center shadow-lg rounded-lg bg-gray-400 my-5 ">
          <div className="w-full border-0 ">
            {step === 1 && <AccommodationBookingForm handleNext={handleNext} />}
            {step === 2 && <AccommodationFilter handleNext={handleNext} />}
            {step === 3 && <Paymentpage />}
          </div>
        </div>
      </div>
    </div>
  );
};

export { AccommodationBookingForm, AccommodationFilter, BookingPage };
