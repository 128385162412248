import React, { useState } from "react";
import data from "./data";

const Filterrooms = () => {
  const rooms_f = data[0].r_filter;
  const [rooms, setRooms] = useState(rooms_f);
  const [selectedCategory, setSelectedCategory] = useState("All");

  const handleCategory = (category) => {
    setSelectedCategory(category);
  };

  const filteredRooms = rooms.filter((room) => {
    if (selectedCategory === "All") {
      return true;
    } else {
      return room.category === selectedCategory;
    }
  });

  return (
    <div className="container mx-auto">
      <div className="flex flex-col flex-wrap justify-center items-center px-auto flex-1 break-words">
        <h1 className="py-3 text-center text-xl font-bold">filter Rooms</h1>
        <div className="py-3 ">
          <button
            onClick={() => handleCategory("All")}
            className="bg-btn-sec hover:bg-nav-gradient-from focus:bg-nav-gradient-from text-white rounded-lg px-3 mx-3"
          >
            All
          </button>
          <button
            onClick={() => handleCategory("Ac")}
            className="bg-btn-sec hover:bg-nav-gradient-from focus:bg-nav-gradient-from text-white rounded-lg px-3 mx-3"
          >
            Ac
          </button>
          <button
            onClick={() => handleCategory("Non Ac")}
            className="bg-btn-sec hover:bg-nav-gradient-from focus:bg-nav-gradient-from text-white rounded-lg px-3 mx-3"
          >
            Non-Ac
          </button>
        </div>
        <div className="b_table overflow-x-scroll w-100">
          <table className="table table-responsive">
            <thead>
              <tr className=" ">
                <th className="">Category</th>
                <th className="">Capacity Min/Max</th>
                <th className="">Room Rent</th>
                <th className="">No. of Rooms</th>
                <th className="">Total Amount</th>
              </tr>
            </thead>
            <tbody className="text-center">
              {filteredRooms.map((r, id) => {
                return (
                  <tr className="odd:bg-white even:bg-slate-200" key={id}>
                    <td>{r.category}</td>
                    <td>{r.cap}</td>
                    <td>{r.rate}</td>
                    <td>{r.a_rooms}</td>
                    <td> _ </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default Filterrooms;
