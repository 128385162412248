import "./App.css";
import { Routes, Route } from "react-router-dom";
import Header from "./components/Header";
import {
  Home,
  Login,
  Signup,
  OnlineBooking,
  Aboutus,
  News,
  Gallery,
  Contact,
  Paymentpage,
  Saitemple,
  Donation,
} from "./pages";
import { BookingPage } from "./pages/BookingForm";
import Mmc from "./components/Mmc";
import Footer from "./components/Footer";
import ScrollButton from "./components/ScrollToTop";
import RoomBookingComponent from "./components/rooms";
import RoomSelection from "./components/roomselection";

function App() {
  return (
    <div className="App">
      <Header />
      <Routes>
        <Route path="/" exact element={<Home />} />
        <Route path="/login" exact element={<Login />} />
        <Route path="/signup" exact element={<Signup />} />
        <Route path="/accomodations" exact element={<OnlineBooking />} />
        <Route path="/booking" element={<BookingPage />} />
        <Route path="/aboutus" exact element={<Aboutus />} />
        <Route path="/news" exact element={<News />} />
        <Route path="/gallery" exact element={<Gallery />} />
        <Route path="/contact" exact element={<Contact />} />
        <Route path="/pay" exact element={<Paymentpage />} />
        <Route path="/temple" exact element={<Saitemple />} />
        <Route path="/donation" exact element={<Donation />} />
        <Route path="/mmc" exact element={<Mmc />} />
        <Route path="/rc" exact element={<RoomBookingComponent />} />
        <Route path="/rs" exact element={<RoomSelection />} />
      </Routes>
      <Footer />
      <ScrollButton />
    </div>
  );
}

export default App;
