import React from "react";
import { BsQrCodeScan } from "react-icons/bs";
import { bl } from "../img";
import { QR } from "../img";

const Donation = () => {
  return (
    <div>
      <div className="container mx-auto">
        <div className="row mb-5">
          <div className="flex flex-wrap justify-center mb-5">
            <h1 class="text-3xl text-heading font-bold pb-7 pt-5"><span class="text-secondary ld-fh-txt all-text-gradient">Donation</span></h1>
          </div>
          <div className="col-md-9">
            <div className=" text-center mx-3">
              <div className="card ">
                <div className="card-header">
                  <h3 className="text-left font-bold text-2xl pb-3">
                    Transfer to Bank Account
                  </h3>
                  <div className="text-left">

                    <table className="table">

                      <tbody>
                        <tr className="border-y-2">
                          <td><b>Account Number:</b> 72670100004847</td>
                        </tr>
                        <tr className="border-y-2">
                          <td><b>Account Name:</b> SHRI SAI BHAKTA SAMAJ</td>
                        </tr>
                        <tr className="border-y-2"> <td><b>Bank Name:</b>
                          <div className="w-[50px]">
                            <img
                              src={bl}
                              alt="Sbi Logo"
                              className="w-full"
                            />
                          </div>
                          Bank of Baroda, Lodhi Road, New Delhi
                        </td>
                        </tr>
                        <tr className="border-y-2">
                          <td><b>IFSC Code:</b> BARBODBLORO</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-3">
            <div className=" max-md:mt-5 mx-3 ">
              <div className="card">
                <div className="card-header">
                  <h3 className="text-center font-bold text-2xl">
                    Transfer Via Qr-Code
                  </h3>
                  <div className="py-2">
                    <div className="w-full place-content-center mix-blend-multiply text-center">
                      <img src={QR} alt="Sbi Logo" className="w-full" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>


      </div>
    </div>
  );
};

export default Donation;
