import React, { useState } from "react";
import { BsQrCodeScan } from "react-icons/bs";
import { bl } from "../img";
import { QR } from "../img";

const Paymentpage = () => {
  return (
    <div className="container mx-auto">
      <div className="flex flex-wrap justify-center">
        <h1 className="pt-5 text-2xl font-bold ">Pay for the Room Booking</h1>

        <div className="w-full px-4 py-5">
          <div className="flex flex-col md:flex-row justify-between items-center">
            <div className="text-center mx-3">
              <div className="card ">
                <div className="card-header">
                  <h3 className="text-center font-bold text-2xl pb-3">
                    Transfer to Bank Account
                  </h3>
                  <div className="">
                    <table className="table">
                      <thead>
                        <tr className="border-y-2">
                          <th className="text-center">Account Number</th>
                          <th className="text-center">Account Name</th>
                          <th className="text-center">Bank Name</th>
                          <th className="text-center">IFSC Code</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr className="border-y-2">
                          <td>72670100004847</td>
                          <td>SHRI SAI BHAKTA SAMAJ</td>
                          <td className="flex justify-center items-center">
                            <div className="w-[50px]">
                              <img src={bl} alt="Sbi Logo" className="w-full" />
                            </div>
                            Bank of Baroda, Lodhi Road, New Delhi
                          </td>
                          <td>BARBODBLORO</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div className="max-md:mt-5 mx-3 ">
              <div className="card">
                <div className="card-header">
                  <h3 className="text-center font-bold text-2xl">
                    Transfer to Via Qr-Code
                  </h3>
                  <div className="max-md:mt-5 mx-3 ">
                    <div className="card">
                      <div className="card-header">
                        <div className="py-2">
                          <div className="w-full place-content-center mix-blend-multiply text-center">
                            <img src={QR} alt="Sbi Logo" className="w-full" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Paymentpage;
