import React, { useEffect, useState } from "react";
import { BsFillPersonFill } from "react-icons/bs";
import { RiLockPasswordFill } from "react-icons/ri";
import jwtDecode from "jwt-decode";
import { Link, useNavigate } from "react-router-dom";
import { BookingPage } from "./BookingForm";

const Login = () => {
  const navigate = useNavigate();

  const [user, setUser] = useState({});
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [isLoggedIn, setLoggedIn] = useState(false);

  const URL = "https://nimittech.com/loginapi/loginapi.php";

  const google = window.google;

  const handleCallbackResponse = (response) => {
    const jwt = jwtDecode(response.credential);
    setUser(jwt);
    document.getElementById("login-cont").hidden = true;
    navigate("/booking");
  };

  useEffect(() => {
    google.accounts.id.initialize({
      client_id:
        "434904689941-uaf4024vevd14jkop86mkee8j0jnqn5r.apps.googleusercontent.com",
      callback: handleCallbackResponse,
    });

    google.accounts.id.renderButton(document.getElementById("login"), {
      theme: "filled_blue",
      size: "short",
    });

    google.accounts.id.prompt();

    const checkLoginStatus = () => {
      // Check if the user is logged in by retrieving the token or user information from storage
      const token = localStorage.getItem("token"); // Retrieve token from local storage

      // Set the login status based on the presence of the token or user information
      if (token) {
        setLoggedIn(true);
      } else {
        setLoggedIn(false);
      }
    };

    checkLoginStatus();
  }, [google.accounts.id]);

  const handleLogout = () => {
    setUser({});
    document.getElementById("login-cont").hidden = false;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (username === "saibaba" && password === "123456") {
      setLoggedIn(true);

      navigate("/booking");
    } else {
      alert("Invalid email or password");
      setLoggedIn(false);
    }

    // fetch(URL, {
    //   method: "POST",
    //   headers: {
    //     "Content-Type": "application/json",
    //   },
    //   body: JSON.stringify({
    //     username: "abhichu",
    //     password: "123456",
    //   }),
    // })
    //   .then((response) => response.json())
    //   .then((data) => {
    //     if (data.success) {
    //       console.log("Login successful!");
    //     } else {
    //       setError(data.message);
    //     }
    //   })
    //   .catch((error) => {
    //     console.error("Error:", error);
    //   });
    setUsername("");
    setPassword("");
  };

  if (isLoggedIn) {
    return (
      <div>
        <button
          className="bg-btn-sec px-3 py-2 ml-5 mt-3"
          onClick={handleLogout}
        >
          SignOut
        </button>
        <BookingPage />
      </div>
    );
  }
  return (
    <div className="container lg:w-2/3 mx-auto py-5">
      <div className="bg-gray-300 text-gray-800 rounded-3xl shadow-xl w-full overflow-hidden">
        <div className="flex justify-center w-full">
          <div
            className="flex-row justify-center items-center py-5 px-5 md:px-10"
            id="login-cont"
          >
            <h2 className="text-lg text-warning">Please Login with Gmail</h2>
            <div className="text-center mb-3">
              <h1 className="font-bold text-3xl text-gray-900">LOGIN</h1>
              <p className="text-gray-900">Enter your credential to login</p>
            </div>
            <form>
              <div>
                <div className="flex -mx-3">
                  <div className="w-full px-3 mb-3">
                    <label
                      htmlFor="l-mail"
                      className="block text-gray-900 font-bold mb-1 md:mb-0 pr-4 text-left"
                    >
                      username
                    </label>
                    <div className="flex">
                      <div className="w-10 z-10 pl-1 text-center pointer-events-none flex items-center justify-center">
                        <BsFillPersonFill className="text-gray-600 text-lg" />
                      </div>
                      <input
                        type="text"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                        className="w-full -ml-10 pl-10 pr-3 py-2 rounded-lg border-2 border-gray-200 outline-none focus:border-gray-900"
                        placeholder="@saibaba"
                        required
                        disabled
                      />
                    </div>
                  </div>
                </div>
                <div className="flex -mx-3">
                  <div className="w-full px-3 mb-3">
                    <label
                      htmlFor="password"
                      className="block text-gray-900 font-bold mb-1 md:mb-0 pr-4 text-left"
                    >
                      Password
                    </label>
                    <div className="flex">
                      <div className="w-10 z-10 pl-1 text-center pointer-events-none flex items-center justify-center">
                        <RiLockPasswordFill className="text-gray-600 text-lg" />
                      </div>
                      <input
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        className="w-full -ml-10 pl-10 pr-3 py-2 rounded-lg border-2 border-gray-200 outline-none focus:border-gray-900"
                        placeholder="************"
                        required
                        disabled
                      />
                    </div>
                  </div>
                </div>
                <div className="flex -mx-3">
                  <div className="w-full px-3">
                    <input
                      className="block w-full max-w-xs mx-auto bg-gradient-to-r from-btn-from to-btn-to hover:bg-nav-gradient-from focus:bg-nav-gradient-from hover:bg-nav-gradient-from focus:bg-nav-gradient-from text-white rounded-lg px-3 py-3 font-semibold"
                      type="submit"
                      onClick={handleSubmit}
                      value="Login"
                    />
                  </div>
                </div>
              </div>
            </form>
            {/*Sign-up with social media*/}
            <div className="flex flex-col items-center justify-center lg:justify-start pt-3">
              <p className="block text-gray-900 font-bold md:text-right mb-2 md:mb-0">
                or
              </p>
              <p className="block text-gray-900 font-bold md:text-right mb-2 md:mb-0">
                Sign in with
              </p>
              <div className="flex flex-row" id="login"></div>
            </div>

            <div className="flex items-center justify-center pt-3">
              <p className="block text-gray-900 font-bold md:text-right mb-2 md:mb-0 pr-3">
                Don't have an account ?
              </p>
              <p className="block text-gray-900 underline font-bold md:text-right mb-2 md:mb-0">
                <a href="/signup" className="text-gray-900 hover:text-heading">
                  Sign up
                </a>
              </p>
            </div>
          </div>
          {Object.keys(user).length !== 0 && (
            <div>
              <button
                className="bg-btn-sec px-3 py-2 ml-5 mt-3"
                onClick={handleLogout}
              >
                Signout
              </button>
              <BookingPage />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Login;
