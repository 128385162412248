import React, { useState, useEffect } from "react";
import axios from "axios";

const Gallery = () => {
  const [gallery, setGallery] = useState([]);

  const URL = "https://nimittech.com/sai_api/gallary.php";

  useEffect(() => {
    const fetchData = async () => {
      const result = await axios(URL);
      setGallery(result.data);
    };
    fetchData();
  }, []);

  return (
    <div>
      <div className="container mx-auto px-5 py-2 lg:px-32 lg:pt-24">
        <div className="-m-1 flex flex-wrap md:-m-2">
          <div className="flex justify-center items-center w-full flex-wrap">
            {gallery.map((item) => {
              return (
                <div key={item.id} className="m-3">
                  <img
                    key={item.id}
                    alt="gallery"
                    className="block h-48 w-48 rounded-lg object-cover object-center"
                    src={item.image}
                  />
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Gallery;
