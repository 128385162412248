import React, { useState, useEffect } from "react";
import pbg from "../img/rectangle-bg.png";
import gal from "../img/gallery.PNG";
import { gal2 } from "../img"
import data from "../components/data";
import { AiOutlineDown } from "react-icons/ai";
import { Hero } from "../components/Hero";
import { DailyTabs } from "../components/tabs";
import { Link } from "react-router-dom";
import axios from "axios";

const Home = () => {
  const [dataSai, setDataSai] = useState(data[0]);

  return (
    <div className="flex-row justify-center items-center text-center">
      <Hero />

      <div className="px-5">
        {/* About */}
        <div className="row justify-center mr-0">
          <div className="flex flex-col items-center w-auto h-full bg-white-100 text-slate-900">
            <div className="flex flex-col items-center w-full h-full py-10  px-16 max-md:px-1">
              <div>
                {/* <h1 className="text-2xl text-heading font-bold pb-7">
                  About us
                </h1> */}

                <h1 class="text-3xl text-heading font-bold pb-7"><span class="text-secondary ld-fh-txt all-text-gradient" >About us</span></h1>
                <p className="pb-8">
                  Shri Sai Bhakta Samaj (Regd). was established in October 1968
                  with its Regd. Office at 27, Rajpur Road, Delhi being the
                  residence of the then President Mr. R.S. Chitnis alongwith the
                  following office bearers and members of Managing Committee
                  with the noble objects to spread Baba's name/teaching amongst
                  the public in General and devotees of Shri Sai Baba in
                  particular, the spirit of universal brotherhood or religious
                  toleration as embodied in Sai Bhakti, high principles of
                  conduct and character without distinction of caste, creed and
                  religion through mandir amongst other objects such as
                  publishing magazines, periodicals, books etc. To promote and
                  carry out services of all kinds which Holy SAI BABA had
                  rendered to humanity. To establish libraries, regional
                  centers, hospitals, Dharamshalas to help the poor and
                  distressed to relieve human sufferings and improve standard of
                  living and conduct. To promote unity among people belonging to
                  different religions, caste and creed and work towards national
                  integration with the following as office bearers and members
                  of the 1st Managing Committee.
                </p>
                <button className="bg-gradient-to-r from-btn-from to-btn-to text-white font-bold py-2 px-4 rounded">
                  <Link to="/aboutus">Read More</Link>
                </button>
              </div>
            </div>
          </div>
        </div>

        {/* News && Apeal */}
        <div className="row justify-center mr-0">
          <div className="flex flex-col md:flex-row items-center justify-center w-full h-full bg-bg-news px-0">
            <div className="flex flex-col w-full h-full ">
              <h1 className="bg-bg-news-header w-full py-3 mt-0 text-slate-200 ">
                Latest News
              </h1>
              <div className="flex flex-col justify-between items-center h-[350px] ">
                <ul className="py-10">
                  {dataSai.notification.map((n) => {
                    return (
                      <li
                        key={n.id}
                        className="text-gray-800 text-start ml-5 leading-6 pb-3 line-clamp-2"
                      >
                        <Link to="/news:id">{n.title}</Link>
                        <p className="text-sm font-thin text-end italic">
                          {n.date}
                        </p>
                      </li>
                    );
                  })}
                </ul>
                <button className="bg-gradient-to-r from-btn-from to-btn-to rounded text-white w-44 h-12 mb-10">
                  <Link to="/news">Read More</Link>
                </button>
              </div>
            </div>
            <div className="flex flex-col items-center w-full h-full bg-bg-appeal">
              <h1 className="bg-bg-appeal-header w-full py-3 text-slate-200 ">
                Latest notification
              </h1>
              <div className="flex flex-col justify-between items-center h-[350px] ">
                <ul className="py-10">
                  {dataSai.appeal.map((ap) => {
                    return (
                      <li
                        key={ap.id}
                        className="text-gray-800 text-start ml-5 leading-6 pb-3"
                      >
                        <p>{ap.text}</p>
                        <p className="text-end">
                          <a
                            href={ap.download}
                            className="text-sm font-thin bg-bg-appeal-header px-2 py-1"
                            type="button"
                          >
                            download
                          </a>
                        </p>
                      </li>
                    );
                  })}
                </ul>
                <button className="bg-gradient-to-r from-btn-from to-btn-to rounded text-white w-44 h-12 mb-10">
                  <a href="#">Read More</a>
                </button>
              </div>
            </div>
          </div>
        </div>

        {/* Gallery */}
        <div className=" row justify-center mr-0 mt-5 mb-5">
          <div className="flex flex-col items-center w-full h-full bg-white py-8">
            <h1 class="text-3xl text-heading font-bold"><span class="text-secondary ld-fh-txt all-text-gradient">Gallery</span></h1>
            <div className="block items-center justify-center w-full h-full bg-white py-7 mx-auto">
              {/* <img
                src={gal}
                alt=""
                className="block py-2 px-1 w-100 text-center"
              /> */}

              <div className="row mt-5">
                <div className="col-md-2">
                  <div class="gallery">
                    <a target="_blank" href="#">
                      <img
                        src={gal2}
                        alt=""
                        className="block w-100 text-center"
                      />
                    </a>
                    <div class="desc">Aarti</div>
                  </div>
                </div>
                <div className="col-md-2">
                  <div class="gallery">
                    <a target="_blank" href="#">
                      <img
                        src={gal2}
                        alt=""
                        className="block w-100 text-center"
                      />
                    </a>
                    <div class="desc">Darshan</div>
                  </div>
                </div>
                <div className="col-md-2">
                  <div class="gallery">
                    <a target="_blank" href="#">
                      <img
                        src={gal2}
                        alt=""
                        className="block w-100 text-center"
                      />
                    </a>
                    <div class="desc">Pooja</div>
                  </div>
                </div>
                <div className="col-md-2">
                  <div class="gallery">
                    <a target="_blank" href="#">
                      <img
                        src={gal2}
                        alt=""
                        className="block w-100 text-center"
                      />
                    </a>
                    <div class="desc">Accomodation</div>
                  </div>
                </div>
                <div className="col-md-2">
                  <div class="gallery">
                    <a target="_blank" href="#">
                      <img
                        src={gal2}
                        alt=""
                        className="block w-100 text-center"
                      />
                    </a>
                    <div class="desc">Publication</div>
                  </div>
                </div>
                <div className="col-md-2">
                  <div class="gallery">
                    <a target="_blank" href="#">
                      <img
                        src={gal2}
                        alt=""
                        className="block w-100 text-center"
                      />
                    </a>
                    <div class="desc">Aarti</div>
                  </div>
                </div>
              </div>

              <div className="row mt-5">
                <div className="col-md-2">
                  <div class="gallery">
                    <a target="_blank" href="#">
                      <img
                        src={gal2}
                        alt=""
                        className="block w-100 text-center"
                      />
                    </a>
                    <div class="desc">Aarti</div>
                  </div>
                </div>
                <div className="col-md-2">
                  <div class="gallery">
                    <a target="_blank" href="#">
                      <img
                        src={gal2}
                        alt=""
                        className="block w-100 text-center"
                      />
                    </a>
                    <div class="desc">Darshan</div>
                  </div>
                </div>
                <div className="col-md-2">
                  <div class="gallery">
                    <a target="_blank" href="#">
                      <img
                        src={gal2}
                        alt=""
                        className="block w-100 text-center"
                      />
                    </a>
                    <div class="desc">Pooja</div>
                  </div>
                </div>
                <div className="col-md-2">
                  <div class="gallery">
                    <a target="_blank" href="#">
                      <img
                        src={gal2}
                        alt=""
                        className="block w-100 text-center"
                      />
                    </a>
                    <div class="desc">Accomodation</div>
                  </div>
                </div>
                <div className="col-md-2">
                  <div class="gallery">
                    <a target="_blank" href="#">
                      <img
                        src={gal2}
                        alt=""
                        className="block w-100 text-center"
                      />
                    </a>
                    <div class="desc">Publication</div>
                  </div>
                </div>
                <div className="col-md-2">
                  <div class="gallery">
                    <a target="_blank" href="#">
                      <img
                        src={gal2}
                        alt=""
                        className="block w-100 text-center"
                      />
                    </a>
                    <div class="desc">Aarti</div>
                  </div>
                </div>
              </div>



            </div>
            <Link to="/gallery">
              <AiOutlineDown className="font-2xl" />
            </Link>


          </div>
        </div>

        {/* Daily Programme */}

        <div className="row w-full justify-center pb-5 ">
          <div
            className="flex flex-col md:flex-row justify-center h-full bg-transparent px-3 py-5"
            style={{ background: `url(${pbg})` }}
          >
            <div className="flex flex-col items-center justify-center w-full h-full bg-transparent">
              <h1 className="text-2xl text-heading font-bold pb-7">
                Daily Programme
              </h1>

              <div className="flex flex-col items-center w-full h-48 overflow-scroll overflow-x-hidden bg-transparent">
                <DailyTabs />
              </div>
            </div>
            <div className="flex flex-col items-center justify-center w-full h-full bg-transparent">
              <h1 className="text-2xl text-heading font-bold pb-7">
                Upcoming Festivals
              </h1>

              <div className="flex flex-col items-center w-full h-48 overflow-scroll overflow-x-hidden bg-transparent"></div>
            </div>
            <div className="flex flex-col items-center justify-center w-full h-full bg-transparent">
              <h1 className="text-2xl text-heading font-bold pb-7">
                Latest Updates
              </h1>

              <div className="flex flex-col items-center w-full h-48 overflow-scroll overflow-x-hidden bg-transparent">
                <ul>
                  {dataSai.d_programme.map((dp) => {
                    return (
                      <div key={dp.id} className="flex  items-center">
                        <li className="text-gray-800 text-start ml-5 leading-6">
                          <p>{dp.time}</p>
                        </li>
                        <li className="text-gray-800 text-start ml-5 leading-6">
                          <p>{dp.title}</p>
                        </li>
                      </div>
                    );
                  })}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
