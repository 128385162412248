import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import Topheader from "./Topheader";

const Header = () => {
  const [open, setOpen] = useState(false);
  return (
    <div className="sticky top-0 w-full z-50">
      <Topheader />
      <div className="w-full z-50 py-2 sm:py-5 bg-gradient-to-r to-nav-gradient-to from-nav-gradient-from">
        <div className="flex items-center justify-center px-3 h-12">
          <div
            onClick={() => setOpen(!open)}
            className="text-2xl text-white absolute right-8 text-end cursor-pointer header-lg:hidden"
          >
            <i className={open ? "bx bx-x" : "bx bx-menu"}></i>
          </div>

          <div>
            <ul
              className={`header-lg:flex header-lg:items-center py-8 absolute header-lg:static header-lg:z-auto z-[-1] left-0 w-full header-lg:w-auto pr-2 ${open
                ? "opacity-100 max-header-md:bg-gradient-to-r to-nav-gradient-to from-nav-gradient-from "
                : "top-[-600px] lg:opacity-100"
                }  lg:opacity-100`}
            >
              <li className="max-md:py-3 hover:text-red header-md:pl-3 header-md:ml-5 text-sm header-md:my-0 header-lg:text-center">
                <NavLink
                  as={NavLink}
                  href="/"
                  to="/"
                  className="line-hover  cursor-pointer lg:pl-3 py-1 px-3  text-gray-100 pt-0.5 font-header font-semibold uppercase text-gray-100  focus:text-gray-100 break-normal text-center"
                  onClick={() => setOpen(!open)}
                >
                  Home
                </NavLink>

              </li>

              <li className="max-md:py-3 hover:text-red header-md:pl-3 header-md:ml-5 text-sm header-md:my-0 header-lg:text-center">
                <NavLink
                  as={NavLink}
                  href="/news"
                  to="/news"
                  className="line-hover cursor-pointer lg:pl-3 py-1 px-3  text-gray-100 pt-0.5 font-header font-semibold uppercase text-gray-100  focus:text-gray-100 break-normal text-center"
                  onClick={() => setOpen(!open)}
                >
                  Notification
                </NavLink>
              </li>

              <li className="max-md:py-3 hover:text-red header-md:pl-3 header-md:ml-5 text-sm header-md:my-0 header-lg:text-center">
                <NavLink
                  as={NavLink}
                  href="/accomodations"
                  to="/accomodations"
                  className="line-hover cursor-pointer lg:pl-3 py-1 px-3  text-gray-100 pt-0.5 font-header font-semibold uppercase text-gray-100  focus:text-gray-100 break-normal text-center"
                  onClick={() => setOpen(!open)}
                >
                  Online Booking
                </NavLink>
              </li>

              <li className="max-md:py-3 hover:text-red header-md:pl-3 header-md:ml-5 text-sm header-md:my-0 header-lg:text-center">
                <span className="md:py-5">
                  <NavLink
                    as={NavLink}
                    href="/donation"
                    to="/donation"
                    className="line-hover cursor-pointer lg:pl-3 py-1 px-3  text-gray-100 pt-0.5 font-header font-semibold uppercase text-gray-100  focus:text-gray-100 break-normal text-center"
                    onClick={() => setOpen(!open)}
                  >
                    Donation
                  </NavLink>
                </span>
              </li>

              <li className="max-md:py-3 hover:text-red header-md:pl-3 header-md:ml-5 text-sm header-md:my-0 header-lg:text-center">
                <NavLink
                  as={NavLink}
                  href="/temple"
                  to="/temple"
                  className="line-hover cursor-pointer lg:pl-3 py-1 px-3  text-gray-100 pt-0.5 font-header font-semibold uppercase text-gray-100  focus:text-gray-100 break-normal text-center"
                  onClick={() => setOpen(!open)}
                >
                  Activities
                </NavLink>
              </li>

              <li className="max-md:py-3 hover:text-red header-md:pl-3 header-md:ml-5 text-sm header-md:my-0 header-lg:text-center">
                <NavLink
                  as={NavLink}
                  href="/contact"
                  to="/contact"
                  className="line-hover cursor-pointer lg:pl-3 py-1 px-3  text-gray-100 pt-0.5 font-header font-semibold uppercase text-gray-100  focus:text-gray-100 break-normal text-center"
                  onClick={() => setOpen(!open)}
                >
                  Contact us
                </NavLink>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
